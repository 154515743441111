import {DIV_IDS} from '../../constants';
import {v4 as uuidv4} from 'uuid';


export function findCellDivs(extractedValues, cellId, rowIndex, tableId){
    /*
    * When click on a cell of table, find the array of selected row and check if we should use selected divIds or 
    * use default divId to navigate to the cell' value
    */ 
    let divIds = null;
    const tableData = extractedValues.get(tableId)[0];
    let selectedRowValues = null;
    for (let i = 1; i < tableData.length; i++) { // Starts from i = 1 to Skip the header
      const innerArray = tableData[i];
      const index = innerArray[innerArray.length - 1]; // Index has been added to the last element of array
      if (index === rowIndex) {
        selectedRowValues = innerArray;
        break;
      }
    }
    if (selectedRowValues){
      const selectedCell = selectedRowValues.find(item => item[1] === cellId);
      if (selectedCell.length > 2){  // divIds are added to the third index of cell
        divIds = selectedCell[2][DIV_IDS];
      }
    }
    return divIds;
}

export function addNewRowToTable(extractedValues, key) {
    const newMap = structuredClone(extractedValues);
    const extractionValue = newMap.get(key);
    const currentLength = extractionValue[0][0].length;
    const clonedArray = [...extractionValue[0]];
    const header = clonedArray[0];
    const newChildArrays = [];
    const arrayLength = extractionValue[0].length;
    for (let i = 0; i < currentLength - 1; i++) {
      newChildArrays.push([null, header[i] + '[' + arrayLength + ']']);
    }
    const uniqueId = uuidv4();
    newChildArrays.push([uniqueId]); // Add index to the last element
    clonedArray.push(newChildArrays);
    newMap.get(key)[0] = clonedArray;
 
    return newMap;
  }
  

  export function deleteRowFromTable(extractedValues, extractionKey, key) {
    const newMap = new Map(extractedValues);
    //changeStyleOfHoveredElement(key, false);
    const extractionValue = newMap.get(extractionKey);
    const newArray = extractionValue[0].filter(innerArray => innerArray[innerArray.length - 1] !== key);
    newMap.get(extractionKey)[0] = newArray;
    return newMap;
  }

  export function isTableValid(values) {
    for (let i = 1; i <  values.length; i++){ // Starts from i = 1 to Skip the header
      const allTextArrayButLastOneAreEmpty = values[i].slice(0, -1).every(arr => arr[0] === null || arr[0].length === 0 ||  arr[0] === '' ); // Index has been added to the last element of array
      if (allTextArrayButLastOneAreEmpty) {
        return false;
      }
    }
    return true;
  }

  export function getTableByKey(key, extractedValues) {
      return extractedValues.get(key);
  }

  export function getTableIndexes(table){
    const tableData =  table[0];
    const indexes = [];
    for ( let i = 1; i < tableData.length; i++){ // skip header
        indexes.push(tableData[i][tableData[i].length - 1][0]); // index is located in the last element of rows' value 
    }

    return indexes;
  }

  export function isTable(data){
    return  Array.isArray(data[0]);
  }

  export function isScheduleEmpty(value){
    return isValueArray(value) &&  
    isFirstElementArray(value[0]) &&
    isHeaderValueEmptyArray(value[0][0]) &&
    isScheduleValueEmptyArray(value[0][1])

    function isValueArray(value){
        return Array.isArray(value) && value.length > 0;
    }

    function isFirstElementArray(value){
      return Array.isArray(value) && value.length > 1;
  }

    function isHeaderValueEmptyArray(header){
        return Array.isArray(header) && header.length === 0;
    }

    function isScheduleValueEmptyArray(body){
        return Array.isArray(body) && body.length === 0;
    }
 }