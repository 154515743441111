import React from 'react';
import '../App.css';
import { useOktaAuth } from '@okta/okta-react';
import logo from '../aixtra.png';
import { Typography } from '@mui/material';

const Login = () => {
    const { oktaAuth } = useOktaAuth();



    const login = async () => {
        await oktaAuth.signInWithRedirect();
    };
    return(
        <div className="login-page" style={{ textAlign: 'center' }}>
            <header style={{ marginBottom: '20px', textAlign: 'center' }}>
                <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100px' }} />
                <h2>
                    <p>Ground Truth Manager</p>
                </h2>
                <div>
                    <button 
                        id="login-button"
                        style={{ background: 'none', border: 'none', color: '#007BFF',
                                 cursor: 'pointer' 
                                }}
                        onClick={(e) => {
                            e.preventDefault();
                            login()
                        }}
                    >
                       <Typography variant='h6'> Login </Typography>
                    </button>
                </div>
            </header>
        </div>
    )
}

export default Login