// import pdfjsWorker from './pdf.worker.min.js';
// import pdfjs from './pdf.min.js';
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '{clientId}';
const ISSUER = process.env.REACT_APP_ISSUER || 'https://{yourOktaDomain}/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

const hostAddress = process.env.REACT_APP_API_URL;

// eslint-disable-next-line
export default {
    pdfjsScript_src: `${process.env.PUBLIC_URL}/dist/pdf.js`,
    pdfjslib_global_worksrc: `${process.env.PUBLIC_URL}/dist/pdf.worker.js`,
    page_tolerance: 5,
    showOcrDivAnnotations: false,
    file_upload_filesize_limit_mb: 50,
    uploadFileValidFormats: ['application/pdf'],
    defaultOcrFontSize: 12,
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'profile', 'email', 'api'],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },
    left_panel_doc_filter: {
        numberOfDays: 45,
        numberOfDocuments: 100,
        minLengthOfSearchWord:2
    },
    pathfinder: {
        maxUnlockDocuments: 5,
    },
    ground_truth_back_end: {
        submitUrl: `${hostAddress}/api/submit`,
        saveUrl: `${hostAddress}/api/save`,
        navFilesUrl: `${hostAddress}/api/nav`,
        fileDataUrl: `${hostAddress}/api/fileData`,
        timeMachineUrl: `${hostAddress}/api/timeMachine`,
        attributePopupUrl: `${hostAddress}/api/attributePopup`,
        standardizedValuesUrl: `${hostAddress}/api/standardizedValues`,
        signedPdfUrl: `${hostAddress}/api/signedPdfUrl`,
        signedUploadFileUrl: `${hostAddress}/api/signedUploadFileUrl`,
        lockDocument: `${hostAddress}/api/pathfinder/lock`,
        lockDocumentUser:`${hostAddress}/api/pathfinder/getJob`,
        getJob:`${hostAddress}/api/pathfinder/getJob`,
        unlockDocumentsUrl: `${hostAddress}/api/pathfinder/bulkUnlock`,
        attribute_validation_issues: `${hostAddress}/api/validationIssues`,
        pathfinder_active_status: `${hostAddress}/api/pathfinder/active/status`,
        ocrValues: `${hostAddress}/api/ocrValues`,
    },
    upload_filetypes: [
        "muni_os",
        "muni_ca",
        "gsac"
    ],
    schedule_size_large: [
        "BDC",
        "DM_BRKR_RESEARCH_REPORTS"
    ],
    aggregation:{
        muni_os:{
            _maturity_schedule:[{'amount': 'sum'}, {'maturity_amount': 'sum'}],
            _series: [{'amount': 'sum'}],
            _sink_schedule: [{'amount': 'sum'}],
            _accretion_schedule: [{'amount': 'sum'}],
            _expected_sink_schedule: [{'amount': 'sum'}]
        },
        muni_ca:{
            bond_retirement_table_defeasance: [{'defeased_amount': 'sum'}],
            bond_retirement_table_redemption: [{'redemption_amount': 'sum'}],
            escrow_details :[{'escrow_amount': 'sum'}]
        },
        ipreo_pos:{
            maturity_schedule:[{'amount': 'sum'}, {'maturity_amount': 'sum'}],
            series: [{'amount': 'sum'}]
        },
        BDC: {
            industry: [{'cost': 'sum'},{'fair_value': 'sum'},{'pct_of_assets': 'sum'}],
            schedule_of_investments: [{'cost': 'sum'}, {'fair_value': 'sum'}]
        },
        private_markets: {
            _repayment_schedule: [{'repayment_amount': 'sum'}]
        },
        CI_FRAC: {
            well_material_details: [{'concentration': 'sum'}]
        }
    },
    attributes_to_display: {
        muni_os: ['_call_schedule', '_call_pricing_schedule', '_put_schedule', '_maturity_schedule', '_sink_schedule', '_credit_enhancement',
            '_entity_type', '_ex_dividend_details', '_special_calls', '_accretion_schedule', '_coupon_step_up_down_schedule', '_bond_insurer_mapping',
            '_tax_status', '_security_code', '_capital_purpose', '_use_of_proceeds', '_series', '_bond_label', '_coupon_payment_terms',
            'issue_description', 'offering_date', 'total_offering_amount',
            'dated_date', 'deferred_coupon_date', 'delivery_date', 'denomination_increase', 'issuer',
            'interest_payment_commencement_date', 'interest_payment_dates', 'is_green_bond',
            'accrued_interest_commencement_date', 'is_rule_144a', 'is_accredited_investor', 'is_callable',
            'is_guarantee', 'is_private_placement', 'minimum_denomination',
            'original_deal_amount', 'project', 'state_name', 'coupon_frequency',
            'day_count_convention', 'county', 'is_remarketed', 'is_new_issue', 'has_extraordinary_redemption',
            'interest_reset_day_of_week', 'interest_reset_frequency', 'mandatory_tender_date', 'reset_date',
            'green_bond_type', 'max_interest_rate', 'min_interest_rate', '_expected_sink_schedule'],
        muni_ca: ['_total_page_numbers','amount_redeemed', 'bond_retirement_table_defeasance', 'bond_retirement_table_redemption', 'document_date','event_announcement_date', '_agent_type',
            'full_call_flag', 'full_call_flag_explain', 'cav_bond_flag', 'cav_bond_flag_explain', 'conditional_flag',
            'conditional_flag_explain', 'revised_flag', 'revised_flag_explain', 'rescinded_flag', 'rescinded_flag_explain', 'escrow_flag', 'escrow_flag_explain',
            'crossover_flag', 'crossover_flag_explain', 'defeasance_flag', 'defeasance_flag_explain', 'event_type', 'escrow_details',
            'event_type_explain'],
        ipreo_pos: [],
        Gsac_A: ['cusip', 'denomination_increase', 'issue_date', 'issue_price', 'issue_size', 'issuer', 'maturity_date', 'min_denom', 'settlement_date',
            'payment_category', 'issue_currency', 'current_coupon', 'coupon_frequency', 'call_option_style_type', 'call_frequency', 'denomination_type',
            'call_schedule_start_date', 'clearing_agency', 'isin', 'dealer', 'local_code', 'first_coupon_date'],
        Gsac_B: ['cusip', 'denomination_increase', 'issue_date', 'issue_price', 'issue_size', 'issuer', 'maturity_date', 'min_denom', 'settlement_date',
            'payment_category', 'issue_currency', 'current_coupon', 'coupon_frequency', 'call_option_style_type', 'call_frequency', 'denomination_type',
            'call_schedule_start_date', 'clearing_agency', 'isin', 'dealer', 'first_coupon_date', 'guarantor'],
        Private_Markets: ['interest_type', 'issue_date', 'maturity_date', 'currency', 'repayment_type', 'interest_payment_type', 'first_interest_payment_date', 'interest_payment_day_of_month',
            'interest_payment_frequency', 'day_count_convention', 'fixing_rate', 'business_day_convention', 'floor_rate', 'cap_floor_type', 'is_step_up_rate', 'margin', 'bank_holiday_region',
            'company_name', 'total_nominal_issue_amount', 'total_nominal_invested_amount', '_facility', 'is_prepayable', 'interest_rate', 'issue_description', 'is_adjusted_accrual_end_date',
            'first_drawdown_date', 'commitment_fee', '_repayment_schedule', 'facility'],
        pcap: [],
        es_credit_agreements: [],
        BDC: [],
        DM_BRKR_RESEARCH_REPORTS: [],
        forecast_summary: ['_forecast_summary', '_ciq_mapped'],
        CI_FRAC: [],
        maritime_esg: [],
        gsac_d: ['is_covered', 'local_code', '_call_schedule', 'day_count_convention','is_mtn_flag','denomination_increase', '_entity_type', 'interest_payment_dates',
            '_maturity_schedule', '_interest_schedule', 'minimum_denomination', 'other_denomination', 'security_context', '_security_identifiers', 'settlement_date', 'is_tax_call',
            'document_issuer_name', 'benchmark_spread', 'register_sensitive_details', 'exdividend_record_details', 'holiday_schedule', 'payment_category_subtype',
            'payment_category', 'selling_restriction_countries', 'governing_law', 'bail_in_option', 'is_death_put', 'denomination_type', 'is_equity_linked', 'extendable_option', 'extendable_date',
            'first_coupon_date', 'is_fund_linked', 'issue_sub_type', 'issue_type', 'interest_accrual_date', 'penultimate_coupon_date', 'tier', 'when_issued',
            'cleanup_call_percent','debt_tier', 'issue_price', 'covenants', 'structured_note_flag', 'syndicated_issuance_indicator', 'pik_schedule', 'islamic_sukuk_details',
            'series_number', 'is_toggle', 'coupon_exchange_rate', 'wkn', 'sfc_chapter_37', 'alternative_principal_currency', 'reoffer_price', '_internal','_use_of_proceeds',
            '_esg_label', '_esg_standards','_esg_use_of_proceeds', '_esg_providers', '_issue_date_schedule', 'base_index_date', 'base_index_value','tranche',
             '_extendable_schedule', '_organization', '_put_schedule', '_instrument_relation', '_sink_schedule', '_principal_details', '_instrument_market_name', '_exchange_offer_details',
             '_convertible_or_exchangeable', 'is_bond_linked','is_commodity_linked','is_coupon_deferrable','is_coupon_cumulative', 'is_currency_linked',
             'collateral_type', 'current_coupon', 'current_day_count_convention', '_amount_details', '_asset_exchange', '_clearing_schedule', 'is_trust_preferred',
             '_fallback_rate_schedule', '_esg_slb_details', 'par_value', 'is_perpetual', 'is_post_determined', '_amortization_schedule', 'is_ratings_sensitive','is_sec_registered']
        },
    attributes_to_exclude: [],
    schedule_pre_defined_types: {
        muni_os: {
            is_accredited_investor: {
                value: ["yes", "no"]
            },
            is_new_issue: {
                value: ["yes", "no"]
            },
            is_callable: {
                value: ["yes", "no"]
            },
            is_guarantee: {
                value: ["yes", "no"]
            },
            is_green_bond: {
                value: ["yes", "no"]
            },
            green_bond_type: {
                value: ["blue bond", "climate bond", "green bond", "social bond", "sustainability bond"]
            },
            is_private_placement: {
                value: ["yes", "no"]
            },
            is_remarketed: {
                value: ["yes", "no"]
            },
            is_rule_144a: {
                value: ["yes", "no"]
            },
            has_extraordinary_redemption: {
                value: ["yes", "no"]
            },
            _call_schedule: {
                start_date: "date",
                end_date: "date",
                method_type: ["cash", "make whole"],
                method_sub_type: ["in part only", "in whole only", "in whole or part", "in whole or from time to time in part"],
                frequency: ["annual", "any int pmt date", "any time", "every 2 months", "biweekly", "daily", "by sched", "every 2 years", "every 3 years", "every 5 years", "every 8 years", "every 10 years", "every 28 days", "every 35 days", "at mat", "mandatory purchase date", "mnthly", "one time", "qrtrly", "semi"],
                start_date_source: ["explicit"],
                make_whole_benchmark: ["treasury rate", "market data rate", "applicable tax-exempt municipal bond rate"]
            },
            _call_pricing_schedule: {
                start_date: "date",
                end_date: "date",
                redemption_type: ["optional", "mandatory", "other"],
                is_premium_pac: ["yes", "no"],
                maturity_date: "date"
            },
            _bond_label: {
                is_premium_pac: ["yes", "no"]
            },
            _put_schedule: {
                put_start_date: "date",
                put_type: ["optional", "mandatory"]
            },
            _sink_schedule: {
                maturity_date: "date",
                date: "date",
                bond_type: ["ADJ RATE", "FRN", "STEP-UP/DOWN", "VAR", "CIB", "CAB", "CCAB", "NOTE"],
                sink_sub_type: ['Lottery', 'Partial', 'Pro-Rata']
            },
            _expected_sink_schedule: {
                date: "date",
            },
            _maturity_schedule: {
                bond_type: ["ADJ RATE", "FRN", "STEP-UP/DOWN", "VAR", "CIB", "CAB", "CCAB", "NOTE"],
                maturity_date: "date",
                is_term_bond: ["yes", "no"],
                coupon_type: ["CAB", "FIX", "VAR"]
            },
            _maturity_schedule_cab: {
                maturity_date: "date",
                is_term_bond: ["yes", "no"]
            },
            _coupon_step_up_down_schedule: {
                start_date: "date",
                end_date: "date"
            },
            _accretion_schedule: {
                maturity_date: "date",
                date: "date"
            },
            _entity_type: {
                entity_type: ['bond counsel', 'clearing agency', 'co-manager', 'escrow agent', 'fiscal agent', 'financial advisor', 'obligor', 'paying agent', 'registrar', 'remarketing agent', 'tender agent', 'transfer agent', 'trustee', 'bond insurer', 'mortgage insurer', 'underwriter', 'lead underwriter', 'guarantor', 'lead manager', 'secondary insurer', 'co-bond counsel']
            },
            _special_calls: {
                type: ["optional", "mandatory"],
                event: ['termination event', 'prepayments', 'unexpended proceeds', 'project completion', 'excess funds', 'insurance', 'condemnation', 'damaged or destroyed', 'invalidity', 'change in control', 'determination of taxability', 'eminent domain', 'grant from us govt or state govt', 'sale of assets', 'net proceeds which the trustee will transfer', 'eligibility status lost on tax exemption', 'project fund release', 'non completion of project', 'event of nonappropriation', 'expiration of loc', 'event of default', 'expiration of credit facility', 'after the event of a sale of all or a portion of the land', 'reduce or eliminate the payment by the treasury of the credit', 'early termination payment', 'expiration date of the letter of credit', 'scheduled principal payments and prepayments', 'as a result of any final determination of a federal court', 'agreement is terminated', 'prepayments of base loan payments', 'following completion of the project', 'monies in the surplus account', 'exercising governmental or judicial authority', 'dispositions of financed property', 'to the extent amounts remain in the tax-exempt', 'in order to maintain such tax-exempt status', 'accelerated upon an event of default', 'destroyed, or damaged', 'determination by a court of competent jurisdiction']
            },
            _ex_dividend_details: {
                record_type: ["nth calendar days prior to payment date", "nth calendar day of month", "nth business day of month", "nth calendar day of prior month", "nth business day of prior month", "nth business days prior to payment date", "last business day of prior month"]
            },
            _tax_status: {
                maturity_date: "date",
                is_bank_qualified: ["yes", "no"],
                is_federal_tax: ["yes", "no"],
                is_state_tax: ["yes", "no"],
                is_amt_tax: ["yes", "no"]
            },
            _security_code: {
                revenue: ['airport revenue', 'car rental tax', 'education loan', 'electric', 'excise or sales tax', 'fuel tax or vehicle tax', 'gas', 'hospital', 'jail revenue', 'lease or rental', 'library revenue', 'loan agreement', 'lottery/gaming revenue', 'mortgage loan', 'nursing home', 'park revenue', 'parking revenue', 'port revenue', 'public welfare', 'recreational facility', 'revenue', 'sales agreement', 'service agreement', 'sewer', 'solid waste disposal', 'special assessment', 'special taxes', 'state appropriation', 'tax allocation', 'tax increment revenue', 'taxes, grants & state aid', 'tobacco settlement', 'toll', 'transportation', 'trust estate pledged', 'tuition and other fee', 'turnpike', 'utility', 'water', 'water & sewer'],
                general_obligation: ['limited tax general obligation', 'unlimited tax general obligation'],
                exceptions: ['yes', 'no']
            },
            _capital_purpose: {
                value: ["advanced refunding", "cancellation of sale", "current refunding", "new issue", "refunding", "remarketing", "restructuring"]
            },
            _use_of_proceeds: {
                value: ["acute care", "agriculture", "airlines", "airport", "assisted living", "bridges", "building", "charter school", "civic/convention centers", "combined utilities", "correction facilities", "correction facilities/courts", "courts", "economic development", "education", "electric & public power", "electricity", "environment protection", "equipment", "flood control/storm drain", "fire station & equipment", "gas", "general purpose", "government/public buildings", "healthcare system", "higher education", "highways", "hospital equipment loans", "hospitals", "housing", "industrial development", "irrigation", "land preservation", "libraries", "libraries/museums", "malls/shopping centers", "mass/rapid transport", "multi-family housing", "museums", "nurse homes", "office building", "other education", "other healthcare", "other housing", "other public service", "other recreation", "other transportation", "other utilities", "parking facilities", "parks", "parks/zoos/beaches", "pension funding/retirement", "police station/equipment", "pollution control", "primary & secondary education", "psychiatric", "public improvements", "public power", "public safety", "rail", "recreation", "redevelopment/ld clearance", "rehabilitation", "resource recovery", "retirement centers", "road/highway", "roads", "sanitation", "seaports/marine terminals", "senior housing", "sewer", "single and multi-family housing", "single family housing", "solid waste", "solid waste/resource recovery", "stadiums/sports complex", "student loans", "swimming pool", "telecommunication", "theaters", "tobacco", "toll roads/highways/streets", "transportation", "trust receipts - portfolio", "tunnels", "various purpose", "veterans", "water", "water & sewer", "zoos/beaches"]
            },
            _coupon_payment_terms: {
                interest_payment_commencement_date: "date"
            },
            _credit_enhancement: {
                credit_type: ["letter of credit", "standby bond purchase agreement", "standby letter of credit", "confirming letter of credit", "school program", "state enhancement program", "bond purchase agreement", "self liquidity"]
            },
            state_name:{
                value:['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'Canal Zone', 'Delaware', 'Florida',
                    'Hawaii', 'Illinois', 'Kansas', 'Louisiana', 'Massachusetts', 'Minnesota', 'Montana', 'Nevada', 'New Mexico',
                    'North Carolina', 'Ohio', 'Oregon', 'Rhode Island', 'South Dakota', 'United States', 'Vermont', 'Washington',
                    'Wisconsin', 'American Samoa', 'California', 'Dist. of Columbia', 'Idaho', 'Kentucky', 'Michigan', 'Nebraska',
                    'New York', 'Oklahoma', 'South Carolina', 'Utah', 'West Virginia', 'Colorado', 'Georgia', 'Indiana', 'Maine',
                    'Mississippi', 'New Hampshire', 'North Dakota', 'Pennsylvania', 'Tennessee', 'Virgin Islands', 'Wyoming', 'Connecticut',
                    'Guam', 'Iowa', 'Maryland', 'Missouri', 'New Jersey', 'Northern Mariana Islands', 'Puerto Rico', 'Texas', 'Virginia']
            }
        },
        muni_ca: {
            bond_retirement_table_defeasance: {
                evt_app_date: "date",
                mat_date: "date",
            },
            bond_retirement_table_redemption: {
                evt_app_date: "date",
                mat_date: "date",
            },
            event_type: {
                // value: ["Full Optional Redemption", "Partial Optional Redemption", "Special Redemption", "Optional Redemption",
                //     "Mandatory Redemption", "Make Whole Redemption", "Full Defeasance", "Partial Defeasance"]
                value: ["Full Optional Redemption","Partial Optional Redemption","Full Defeasance","Mandatory Redemption","Optional Redemption","Partial Defeasance","Special Redemption","Make Whole Redemption Event"]
            },
            escrow_type: {
                value: ["As per Investement Agreement", "Bank CD", "Bank Certificate of Deposits", "FHLMC Securities", "FICO Obligations",
                    "FNMA Securities", "GNMA Securities", "Open Market Treasuries", "Other Escrow", "REFCO Obligations", "Refcorp Obligations",
                    "Refcorp/Fico Obligations", "SLGS Securities", "Slugs", "Slugs/Open Market Treasuries", "U.S. Govt Securities",
                    "United States Govt Securities", "Various Obligations"]
            }

        },
        ipreo_pos: {
            is_accredited_investor: {
                value: ["yes", "no"]
            },
            is_new_issue: {
                value: ["yes", "no"]
            },
            is_callable: {
                value: ["yes", "no"]
            },
            is_guarantee: {
                value: ["yes", "no"]
            },
            is_green_bond: {
                value: ["yes", "no"]
            },
            green_bond_type: {
                value: ["blue bond", "climate bond", "green bond", "social bond", "sustainability bond"]
            },
            is_private_placement: {
                value: ["yes", "no"]
            },
            is_remarketed: {
                value: ["yes", "no"]
            },
            is_rule_144a: {
                value: ["yes", "no"]
            },
            has_extraordinary_redemption: {
                value: ["yes", "no"]
            },
            call_schedule: {
                start_date: "date",
                end_date: "date",
                method_type: ["cash", "make whole"],
                method_sub_type: ["in part only", "in whole only", "in whole or part", "in whole or from time to time in part"],
                frequency: ["annual", "any int pmt date", "any time", "every 2 months", "biweekly", "daily", "by sched", "every 2 years", "every 3 years", "every 5 years", "every 8 years", "every 10 years", "every 28 days", "every 35 days", "at mat", "mandatory purchase date", "mnthly", "one time", "qrtrly", "semi"],
                start_date_source: ["explicit"],
                make_whole_benchmark: ["treasury rate", "market data rate", "applicable tax-exempt municipal bond rate"]
            },
            call_pricing_schedule: {
                start_date: "date",
                end_date: "date",
                redemption_type: ["optional", "mandatory", "other"],
                is_premium_pac: ["yes", "no"],
                maturity_date: "date"
            },
            bond_label: {
                is_premium_pac: ["yes", "no"]
            },
            put_schedule: {
                put_start_date: "date",
                put_type: ["optional", "mandatory"]
            },
            sink_schedule: {
                maturity_date: "date",
                date: "date",
                bond_type: ["ADJ RATE", "FRN", "STEP-UP/DOWN", "VAR", "CIB", "CAB", "CCAB", "NOTE"],
                sink_sub_type: ['Lottery', 'Partial', 'Pro-Rata']
            },
            expected_sink_schedule: {
                date: "date",
            },
            maturity_schedule: {
                bond_type: ["ADJ RATE", "FRN", "STEP-UP/DOWN", "VAR", "CIB", "CAB", "CCAB", "NOTE"],
                maturity_date: "date",
                is_term_bond: ["yes", "no"],
                coupon_type: ["CAB", "FIX", "VAR"]
            },
            coupon_step_up_down_schedule: {
                start_date: "date",
                end_date: "date"
            },
            accretion_schedule: {
                maturity_date: "date",
                date: "date"
            },
            entity_type: {
                entity_type: ['bond counsel', 'clearing agency', 'co-manager', 'escrow agent', 'fiscal agent', 'financial advisor', 'obligor', 'paying agent', 'registrar', 'remarketing agent', 'tender agent', 'transfer agent', 'trustee', 'bond insurer', 'mortgage insurer', 'underwriter', 'lead underwriter', 'guarantor', 'lead manager', 'secondary insurer', 'co-bond counsel']
            },
            special_calls: {
                type: ["optional", "mandatory"],
                event: ['termination event', 'prepayments', 'unexpended proceeds', 'project completion', 'excess funds', 'insurance', 'condemnation', 'damaged or destroyed', 'invalidity', 'change in control', 'determination of taxability', 'eminent domain', 'grant from us govt or state govt', 'sale of assets', 'net proceeds which the trustee will transfer', 'eligibility status lost on tax exemption', 'project fund release', 'non completion of project', 'event of nonappropriation', 'expiration of loc', 'event of default', 'expiration of credit facility', 'after the event of a sale of all or a portion of the land', 'reduce or eliminate the payment by the treasury of the credit', 'early termination payment', 'expiration date of the letter of credit', 'scheduled principal payments and prepayments', 'as a result of any final determination of a federal court', 'agreement is terminated', 'prepayments of base loan payments', 'following completion of the project', 'monies in the surplus account', 'exercising governmental or judicial authority', 'dispositions of financed property', 'to the extent amounts remain in the tax-exempt', 'in order to maintain such tax-exempt status', 'accelerated upon an event of default', 'destroyed, or damaged', 'determination by a court of competent jurisdiction']
            },
            ex_dividend_details: {
                record_type: ["nth calendar days prior to payment date", "nth calendar day of month", "nth business day of month", "nth calendar day of prior month", "nth business day of prior month", "nth business days prior to payment date", "last business day of prior month"]
            },
            tax_status: {
                maturity_date: "date",
                is_bank_qualified: ["yes", "no"],
                is_federal_tax: ["yes", "no"],
                is_state_tax: ["yes", "no"],
                is_amt_tax: ["yes", "no"]
            },
            security_code: {
                revenue: ['airport revenue', 'car rental tax', 'education loan', 'electric', 'excise or sales tax', 'fuel tax or vehicle tax', 'gas', 'hospital', 'jail revenue', 'lease or rental', 'library revenue', 'loan agreement', 'lottery/gaming revenue', 'mortgage loan', 'nursing home', 'park revenue', 'parking revenue', 'port revenue', 'public welfare', 'recreational facility', 'revenue', 'sales agreement', 'service agreement', 'sewer', 'solid waste disposal', 'special assessment', 'special taxes', 'state appropriation', 'tax allocation', 'tax increment revenue', 'taxes, grants & state aid', 'tobacco settlement', 'toll', 'transportation', 'trust estate pledged', 'tuition and other fee', 'turnpike', 'utility', 'water', 'water & sewer'],
                general_obligation: ['limited tax general obligation', 'unlimited tax general obligation'],
                exceptions: ['yes', 'no']
            },
            capital_purpose: {
                value: ["advanced refunding", "cancellation of sale", "current refunding", "new issue", "refunding", "remarketing", "restructuring"]
            },
            use_of_proceeds: {
                value: ["airports", "combined utilities", "economic development", "primary & secondary education", "higher education",
                                             "other education", "libraries & museums", "office buildings", "multi-family housing bonds", "nursing homes",
                                             "life care & retirement", "agriculture", "convention & civic centers", "correctional facilities",
                                             "fire stations & equipment", "general purpose/public improvement", "government bulidings",
                                             "other recreation", "police station & equipment", "stadiums & sports complexes", "telephone",
                                             "theatres", "veterans", "pollution control", "public power", "student loans", "single family housing",
                                             "multi-family housing", "sanitation", "toll roads, highways & street improvements", "bridges",
                                             "tunnels", "public parking facilities", "mass transportation", "other transportation", "water & sewer",
                                             "gas", "seaports, marine terminals"]
            },
            coupon_payment_terms: {
                interest_payment_commencement_date: "date"
            },
            credit_enhancement: {
                credit_type: ["letter of credit", "standby bond purchase agreement", "standby letter of credit", "confirming letter of credit", "school program", "state enhancement program", "bond purchase agreement", "self liquidity"]
            },
            ratings: {
                type: ["Moodys", "S & P", "Fitch", "Kroll"]
            }
        },
        gsac: {
            _call_schedule: {
                start_date: "date",
                end_date: "date",
                method_type: ["cash", "make whole"],
                method_sub_type: ["in part only", "in whole only", "in whole or part"],
                frequency: ["annual", "any int pmt date", "any time", "every 2 months", "biweekly", "daily", "by sched", "every 2 years", "every 3 years", "every 5 years", "every 8 years", "every 10 years", "every 28 days", "every 35 days", "at mat", "mandatory purchase date", "mnthly", "one time", "qrtrly", "semi"]
            },
            _maturity_schedule: {
                bond_type: ["ADJ RATE", "FRN", "STEP-UP/DOWN", "VAR", "CIB", "CAB", "CCAB", "NOTE"],
                maturity_date: "date",
                is_term_bond: ["yes", "no"],
                coupon_type: ["FIX", "VAR"]
            }
        },
        Insurance: {},
        Insurance_apac: {},
        Insurance_emea: {},
        Dividend_Distribution: {
            div_payment_schedule: {
                ex_date: "date",
                record_date: "date",
                pay_date: "date"
            }
        },
        private_markets: {
            is_step_up_rate: {
                value: ["yes", "no"]
            },
            interest_type: {
                value: ["fixed rate", "floating"]
            },
            drawn: {
                value: ["delayed drawdown", "fully drawn"]
            },
            repayment_type: {
                value: ["amortising", "bullet"]
            },
            is_prepayable: {
                value: ["yes", "no"]
            },
            is_adjusted_accrual_end_date: {
                value: ["yes", "no"]
            },
            business_day_convention: {
                value: ["following", "modified following"]
            },
            issue_date: {
                value: "date"
            },
            maturity_date: {
                value: "date"
            },
            first_drawdown_date: {
                value: "date"
            }
        },
        forecast_summary: {
            _forecast_summary: {
                forecast_date: "date"
            }
        },
        pcap: {
            financials: {
                period_type: ["QTD", "YTD", "ITD"]
            }
        },
        es_credit_agreements: {
            entity_details: {
                type: [ "borrower", "parent borrower", "co-borrower", "guarantor", 'administrative agent', 'co-administrative agent', 'collateral agent', "deal sponsor", "syndication agent", "underwriter"]
            },
            is_public_loan: {
                value: ["yes", "no"]
            },
        },
        BDC: {},
        DM_BRKR_RESEARCH_REPORTS: {},
        CI_FRAC: {},
        maritime_esg: {},
        gsac_d: {
            _maturity_schedule: {
                maturity_date: "date",
                coupon_type: ["FIX", "FLT", "FIX/FLT"],
                issue_currency: ['ARS','AUD','BDT','BGN','BRL','BWP','CAD','CHF','CLF','CLP','CNY','COP','COU','CRC','CZK','DEM','DKK','DOP','EGP','EUR','FRF','GBP','GBX','GEL','GHS','HKD','HRK','HUF','IDR','ILS','INR','ISK','ITL','JMD','JPY','KES','KRW','KZT','LBP','LKR','MAD','MXN','MXV','MYR','NAD','NGN','NLG','NOK','NZD','PEN','PHP','PKR','PLN','QAR','RON','RSD','SAR','SEK','SGD','THB','TRY','TWD','TZS','UAH','UGX','USD','UYI','UYU','UZS','VND','XOF','ZAR','ZMW','ATS','SKK','PRI','CUW']
            },
            _issue_date_schedule: {
                issue_date: "date"
            },
            _security_identifiers: {
                type: ["144a", "regs", "no_label"],
                is_temporary: ["yes", "no"],
                is_accredited_investor: ["yes", "no"],
                is_regulation_d: ["yes", "no"],
                is_tefra_c: ["yes", "no"],
                is_tefra_d: ["yes", "no"],
                is_private_placement: ["yes", "no"],
                has_144a_registration_right: ["yes", "no"],
            },
            _call_schedule: {
                start_date: "date",
                end_date: "date",
                method_type: ["cash", "make whole"],
                method_sub_type: ["in part only", "in whole only", "in whole or part", "in whole or from time to time in part"],
                frequency: ["annual", "any int pmt date", "any time", "every 2 months", "biweekly", "daily", "by sched", "every 2 years", "every 3 years", "every 5 years", "every 8 years", "every 10 years", "every 28 days", "every 35 days", "at mat", "mandatory purchase date", "mnthly", "one time", "qrtrly", "semi"],
                start_date_source: ["explicit"],
                make_whole_benchmark: ["treasury rate", "market data rate", "applicable tax-exempt municipal bond rate"],
                maturity_date: "date",
            },
            _entity_type: {
                entity_type: ['additional credit backer', 'administrative agent', 'agent', 'arranger', 'auction agent', 'auditor', 'bond counsel', 'bond insurer', 'bookrunner', 'borrower', 'calculation agent', 'clearing agency', 'co-issuer', 'co-lead manager', 'co-manager', 'collateral agent', 'custodian agent', 'dealer', 'defendent counsel', 'depositor', 'depository agent', 'escrow agent', 'exchange agent', 'financial advisor', 'financial guarantor', 'fiscal agent', 'guarantor', 'insurer', 'issuing & booking agent', 'issuing agent', 'joint bookrunners', 'joint global coordinators', 'joint lead managers', 'keepwell provider', 'lead manager', 'lead underwriter', 'legal advisor', 'listing agent', 'manager', 'obligor', 'originator', 'other', 'paying agent', 'placement agent', 'plantiff council', 'principal paying agent', 'registrar', 'sblc provider', 'secondary insurer', 'servicer', 'settlement agent', 'syndicate member', 'tender agent', 'transfer agent', 'trustee', 'underwriter', 'warrant agent']
            },
            _interest_schedule: {
                start_date: "date",
                end_date: "date",
                coupon_type: ["FIX", "FLT", "FIX/FLT"],
                day_count_convention: ['30/360', 'ACT/360', 'ACT/365', 'ACT/ACT', 'Actual/Actual ICMA', 'ISMA-30/360', 'RBA Bond Basis'],
                index_name: ["CORRA Compounded Index","UKRPI","SONIA Compounded Index","Israel Consumer Price Index","SOFR Compounded Index",
                    "EU HICP ex Tobacco","FOI ex-tobacco","South Africa Consumer Price Index","Denmark Consumer Price Index","Iceland Consumer Price Index","Canada Consumer Price Index",
                    "United Kingdom Consumer Price Index","UNITED STATES Consumer Price Index","Mexico Consumer Price Index","Uruguay Consumer Price Index"],
                reset_frequency:["Daily", "Semi", "Annual", "Qrtrly","Mnthly", "Weekly", "One Time", "Every 5 years", "At Mat", "By Sched", "Every 182 days", "Every 2 months",
                    "Every 28 days", "Every 4 months", "Every 90 days"],
                pay_calendar_value: "date",
                business_day_convention: ['following', 'modified following'],

            },
            covenants: {
                is_change_of_control_covenant: ['yes', 'no']
            },
            islamic_sukuk_details: {
                sukuk_type: ['BBA', 'Bai Inah', 'Bai al-Dayn', 'Ijarah', 'Istisna', 'Istithmar', 'MTN Ijarah', 'Mudarabah', 'Murabahah', 'Musharakah', 'Salam', 'Wakala', 'short term Sal', 'Islamic Sukuk', 'BAI AT-TAWARRUQ']
            },
            pik_schedule: {
                security_type: ['Bonds', 'Interest Payment in Cash, optionally in securities', 'Cash'],
                start_date: "date",
                end_date: "date",
            },
            register_sensitive_details: {
                start_date: "date",
                end_date: "date",
            },
            holiday_schedule: {
                holiday_calendar: ["ADB", "ADLD", "AE", "AMST", "ANK", "ANT", "ARG", "ATBK", "ATHN", "AUBK", "AUCK", "BARC", "BEBK", "BGD", "BHR", "BJG", "BKOK", "BMU", "BOL", "BRA", "BRAT", "BRB", "BRIS", "BRN", "BRSS", "BSL", "BUAR", "BUDA", "BWA", "CABK", "CANB", "CHBK", "CHRL", "CI", "CL", "CLGY", "CN", "CO", "COPE", "CR", "CRC", "CY", "DBAI", "DEBK", "Delaware", "DMK", "DO", "DSDF", "DUBL", "EC", "ECG", "EDMN", "EG", "ESBK", "FIN", "FRAM", "FRBK", "GE", "GENV", "GH", "GRC", "GS", "GT", "GU", "HELS", "HMG", "HN", "HONK", "HR", "IE", "IL", "IM", "INA", "IND", "IS", "ITBL", "ITLY", "JE", "JHAN", "JKTA", "JM", "JPBK", "KE", "KG", "KIEV", "KULU", "KYBK", "KZ", "LB", "LK", "LNBK", "LSBN", "LUXM", "MA", "Maryland", "Massachusetts", "MDRD", "MINN", "MLAN", "MLBN", "MLY", "MN", "MNL", "MNPS", "MNTR", "MO", "MR", "MSCW", "MSL", "MT", "MU", "MUM", "MUNI", "MX", "MXCT", "NASD", "NB", "NCRL", "NG", "NLBK", "NWDL", "NWY", "NYBK", "NYSE", "NZBK", "OM", "Ontario", "OSLO", "OTTA", "PA", "PARI", "PE", "PH", "PK", "PRAG", "PRTH", "PTBG", "PY", "QA", "QBC", "RO", "ROME", "RS", "RTT", "SA", "SEOL", "Shanghai", "SI", "SING", "SKHM", "SOFI", "SPLO", "STGO", "STT", "SV", "SWD", "SY", "SYDN", "TELV", "Texas", "TG", "THLD", "TKYO", "TLLN", "TPEI", "TR", "TRGT", "TRN", "TRTO", "TT", "TW", "TZ", "UA", "UCH", "UG", "UY", "UZ", "VE", "VG", "VIEN", "VN", "VNE", "WELL", "WRSW", "ZM", "ZURI", "SLK", "FRBNY"],
                detail: ["Interest Payment and Principal Payment", "Interest Payment", " Principal Payment and Rate reset", "Principal Payment", "Rate Reset"]
            },
            selling_restriction_countries: {
                country: ["ABW", "AGO", "AND", "ANT", "ARE", "ARG", "ARM", "ASM", "AUS", "AUT", "AZE", "BEL", "BEN", "BFA", "BGR", "BHR", "BHS", "BLR", "BLZ", "BMU", "BOL", "BRA", "BRB", "BRN", "BWA", "CAF", "CAN", "CHE", "CHL", "CHN", "CIV", "COG", "COK", "COL", "CPV", "CRI", "CUB", "CUW", "CYM", "CYP", "CZE", "DEU", "DNK", "DOM", "ECU", "EEA", "EGY", "ESP", "EST", "FIN", "FRA", "GAB", "GBR", "GEO", "GGY", "GIB", "GIN", "GMB", "GNB", "GRC", "GRD", "GTM", "GUM", "HKG", "HND", "HRV", "HUN", "IDN", "IMN", "IND", "IRL", "IRN", "ISL", "ISR", "ITA", "JAM", "JEY", "JOR", "JPN", "KAZ", "KOR", "KWT", "LBN", "LIE", "LKA", "LSO", "LTU", "LUX", "LVA", "MAC", "MAF", "MAR", "MCO", "MEX", "MKD", "MLI", "MLT", "MMR", "MUL", "MUS", "MYS", "NAM", "NER", "NGA", "NIC", "NLD", "NOR", "NZL", "OMN", "PAK", "PAN", "PER", "PHL", "PNG", "POL", "PRI", "PRK", "PRT", "PRY", "QAT", "ROU", "RUS", "SAU", "SCG", "SDN", "SEN", "SGP", "SHN", "SLV", "SMR", "SPM", "SVK", "SVN", "SWE", "SWZ", "SYR", "TGO", "THA", "TTO", "TUR", "TUV", "TWN", "UKR", "URY", "USA", "UZB", "VEN", "VGB", "VIR", "WSM", "ZAF", "ZMB"]
            },
            payment_category: {
                value: ["FIX", "FLT", "RNG", "VAR", "UNKNOWN"]
            },
            payment_category_subtype: {
                value: ["Fixed: Pay only at Maturity", "Fixed: Plain Vanilla Fixed Coupon", "Fixed: Zero Coupon", "Floating: Bull/Reverse Floating Rate", "Floating: Fixed Margin over Index", "Floating: Fixed then Floating", "Floating: Fixed then Reverse Float", "Floating: Floating", "Floating: Floating then Fixed", "Floating: Pay at Maturity", "Floating: Step Down-Margin over Index", "Floating: Step Up-Margin over Index", "Floating: Variable then Float", "Floating: Variable then Reverse Float", "Floating: Zero Then Floating", "Range: Range", "Variable: Fixed Then Zero Coupon", "Variable: Step Up/Step Down", "Variable: Variable then Zero", "Variable: Zero Then Fixed", "UNKNOWN"]
            },
            exdividend_record_details: {
                exdividend_rules: ["Nth Business days after record date", "Nth Business days prior to payment date", "Nth Business days prior to record date", "Nth Calendar Day of Month", "Nth Calendar Day of Prior Month", "Nth Calendar days after record date", "Nth Calendar days prior to payment date", "Nth Calendar days prior to record date", "Nth day prior to coupon payment date"],
                record_date_rules: ["Nth Business days after record date", "Nth Business days prior to payment date", "Nth Business days prior to record date", "Nth Calendar Day of Month", "Nth Calendar Day of Prior Month", "Nth Calendar days after record date", "Nth Calendar days prior to payment date", "Nth Calendar days prior to record date", "Nth day prior to coupon payment date"],
            },
            bail_in_option: {
                value: ['Bail-In CTE', 'Bail-In', 'Bail-In CTE/PWD', 'Bail-In PWD']
            },
            denomination_type: {
                value: ['Asset Backed Security', 'Bail-In Security', 'Bank Note', 'Bankers Acceptance', 'Bill', 'Bond', 'Bond Warrants', 'Catastrophe Bonds', 'Certificate', 'Certificate of Participation', 'Certificates of Deposit', 'Church Bond', 'Collateralised Debt Obligations', 'Commercial Paper', 'Contingent Convertible', 'Convertible', 'Coupon Strip', 'Covered Bond', 'Credit Derivatives', 'Credit Linked Note', 'Debtor Warrants', 'Deposit Note', 'Equity', 'Exchangeable', 'Global Depository Note', 'Hybrid', 'Hybrid Warrant', 'Islamic Sukuk', 'Loan Notes', 'Loan Participation Note', 'Loan Stock', 'Mortgage Backed Security', 'Note', 'Pass Through Certificate', 'Permanent Interest Bearing Share', 'Preferred Security (Trust, SPV)', 'Preferred Stock', 'Principal Strip', 'Promissory Notes', 'Retail Notes', 'Reverse Convertible', 'Savings Bond', 'Security Certificates', 'STRIP', 'Sukuk', 'Sukuk Bai Inah', 'Sukuk BBA', 'Sukuk Hybrid', 'Sukuk Ijarah', 'Sukuk Istisna', 'Sukuk Istithmar', 'Sukuk Mudarabah', 'Sukuk Murabahah', 'Sukuk Musharakah', 'Sukuk Wakala', 'SURE', 'Surplus Notes', 'Trust Certificate', 'Units']
            },
            issue_type: {
                value: ['Bond', 'Certificate', 'Convertible', 'Credit Derivatives', 'Equity', 'Hybrid', 'Loan', 'Money Market Instrument', 'Note', 'Securitized Instrument', 'STRIP', 'Sukuk', 'Warrant']
            },
            issue_sub_type: {
                value: ['BER', 'BES', 'BEU', 'BKE', 'BKR', 'BPGL', 'BRG', 'CTF', 'REC', 'REG', 'ROB', 'RPGL', 'TGL']
            },
            is_mtn_flag: {
                value: ["yes", "no"]
            },
            is_sec_registered: {
                value: ["yes", "no"]
            },
            is_tax_call:{
                value: ["yes", "no"]
            },
            structured_note_flag:{
                value: ["yes", "no"]
            },
            syndicated_issuance_indicator:{
                value: ["yes", "no"]
            },
            _internal: {
                offer_to_exchange_indicator: ["yes", "no"],
                offer_to_exchange_issue_date: "date",
            },
            sfc_chapter_37:{
                value: ["yes", "no"]
            },
            is_toggle:{
                value: ["yes", "no"]
            },
            _extendable_schedule: {
                day_count: ['30/360', 'ACT/360', 'ACT/365', 'ACT/ACT', 'Actual/Actual ICMA', 'ISMA-30/360', 'RBA Bond Basis'],
                effective_coupon_type: ["FIX", "FLT", "VAR"],
                coupon_frequency: ["Annual","Mnthly", "One Time", "Qrtrly", "Semi", "Unknown", "ZERO"],
                frequency: ["Annual", "Any Time", "At Mat", "Daily", "Every 3 years", "Mnthly", "On 22nd day of any month", "On 24th day of any month", "One Time", "Qrtrly", "Semi"],
                interest_payment_currency: ["AUD", "CAD", "CHF", "CNY", "EUR", "GBP", "IDR", "ISK", "MYR", "NOK", "SEK", "SGD", "THB", "TRY", "USD"],
                start_date: "date",
                end_date: "date"
            },
            _instrument_relation: {
                effective_date: "date",
                identifier_type: ["Bloomberg ID", "CUSIP", "ICB", "ISIN", "Luxemburg Stock Exchange Code", "Valoren"],
                instrument_relation_type: ['144A', '144A Exchanged', '144A Funged - Baby', 'Accredited', 'Disbursed Security', 'Escrow', 'Exchanged', 'Funged - Baby', 'Funged - Mother', 'GDN', 'ISIN Change', 'N Global Receipt', 'N Receipt', 'Original of Escrow', 'Partially Exchanged', 'Predecessor', 'Predecessor of Exchanged', 'Re-Offered', 'Re-Opened', 'Receipt', 'Registered', 'RegS', 'RegS Exchanged', 'Regulation S Funged - Baby', 'Remarketed', 'Successor', 'Tendered from', 'Tendered into', 'Underlying ISIN of GDN', 'X Global Receipt', 'X Receipt']
            },
             _sink_schedule: {
                type: ["Mandatory Sinking Fund", "Optional"],
                frequency: ['Annual', 'By Sched', 'Mnthly', 'Semi'],
                start_date: "date"
            },
            _principal_details:{
                index_name: ['Argentina Consumer Price Index', 'Australia Consumer Price Index', 'Basket of Indices', 'BRAZIL Consumer Price Index', 'Canada Consumer Price Index', 'Chile Consumer Price index', 'Colombia Consumer Price Index', 'Denmark Consumer Price Index', 'Dow Jones Euro Stoxx 50', 'Dow Jones Industrial Average', 'EU HICP ex Tobacco', 'EURO STOXX 50', 'FOI ex-tobacco', 'FRANCE Consumer Price Index', 'FTSE 100 Index', 'GDP Linked', 'Gross Domestic Product of Argentina', 'Iceland Consumer Price Index', 'Inflation Linked Index', 'Israel Consumer Price Index', 'JAPAN Consumer Price Index', 'Mexico Consumer Price Index', 'MSCI EAFE (Europe, Australasia, and Far East)', 'NASDAQ-100', 'New Zealand Consumer Price Index', 'Nikkei 225', 'Other', 'Poland Consumer Price Index', 'Russell 2000', 'S&P 500', 'S&P/ASX 200', 'SELIC', 'South Africa Consumer Price Index', 'Sweden Consumer Price Index', 'Thailand Consumer Price Index', 'TURKEY Consumer Price Index', 'UKRPI', 'United Kingdom Consumer Price Index', 'UNITED STATES Consumer Price Index', 'Uruguay Consumer Price Index']
            },
            _put_schedule:{
                start_date: "date",
                end_date: "date",
                frequency: ['Annual', 'Any Time', 'By Sched', 'Every 10 years', 'Every 13 years', 'Every 2 years', 'Every 3 years', 'Every 5 years', 'Mnthly', 'One Time', 'Qrtrly', 'Semi'],
                notice_days_convention: ['Business Days', 'Calendar Days'],
                method_sub_type: ['Circumstance Put', 'N/A', 'Puttable'],
                method_type: ['Circumstance Put', 'Puttable']
            },
            is_bond_linked: {
                value: ["yes", "no"]
            },
            is_commodity_linked: {
                value: ["yes", "no"]
            },
            is_coupon_deferrable: {
                value: ["yes", "no"]
            },
            is_coupon_cumulative: {
                value: ["yes", "no"]
            },
            is_currency_linked: {
                value: ["yes", "no"]
            },
            _convertible_or_exchangeable:{
                start_date: "date",
                end_date: "date"
            },
            _amount_details:{
                effective_date: "date",
            },
            _asset_exchange:{
                listing_date: "date",
            },
            is_trust_preferred:{
                value: ["yes", "no"]
            },
            _fallback_rate_schedule:{
                fallback_rate_applicable: ["yes", "no"]
            },
            _amortization_schedule:{
                start_date: "date",
                end_date: "date",
                frequency: ['Annual', 'By Sched', 'Every 10 years', 'Every 2 months', 'Every 2 years', 'Every 3 years', 'Every 4 years', 'Every 5 years', 'Mnthly', 'One Time', 'Qrtrly', 'Semi', 'Unknown']
            },
            _esg_slb_details: {
                effective_date: "date",
                reference_date: "date",
            },
            is_perpetual:{
                value: ["yes", "no"]
            },
            is_post_determined:{
                value: ["yes", "no"]
            },
            is_ratings_sensitive:{
                value: ["yes", "no"]
            },
        }
    },


    schedule_fields: {
        'muni_os': {
            '_maturity_schedule': ["cusip", "maturity_date", "amount", "interest_rate", "price", "yield_pct", "is_term_bond", "coupon_type", "index_name", "multiplier", "spread", "maturity_amount", "interest_rate_2", "_UNIQUE_ID"],
            '_maturity_schedule_cab': ["cusip", 'maturity_date', 'original_issue_amount', 'maturity_amount', 'accretion_rate', 'interest_rate', 'price', 'yield_pct', 'is_term_bond', "coupon_type", "_UNIQUE_ID"],
            '_call_schedule': ['cusip', 'start_date', 'end_date', 'method_type', 'method_sub_type', 'frequency', 'price', 'make_whole_spread', 'make_whole_benchmark', 'max_notice_days', 'min_notice_days', 'anniversary_years', 'maturity_date', "_UNIQUE_ID"],
            '_call_pricing_schedule': ['start_date', 'end_date', 'price', "redemption_type", "is_premium_pac", "maturity_date", "maturity_year", "_UNIQUE_ID"],
            '_put_schedule': ['cusip', 'put_start_date', 'put_type', 'price', 'max_notice_days', 'min_notice_days', "_UNIQUE_ID"],
            '_sink_schedule': ['cusip', 'date', 'amount', 'accreted_value', 'redemption_price', 'sink_sub_type', '_UNIQUE_ID'],
            '_expected_sink_schedule': ['cusip', 'date', 'amount', 'tranche', 'explain', '_UNIQUE_ID'],
            '_accretion_schedule': ['cusip', 'date', 'amount', '_UNIQUE_ID'],
            '_credit_enhancement': ['program_name', 'legal_code','provider_name', 'credit_type', 'expiration_date', 'explain', '_UNIQUE_ID'],
            '_entity_type': ['entity_type', 'entity_name', 'entity_city', 'entity_state', 'explain', '_UNIQUE_ID'],
            '_bond_insurer_mapping': ['bond_insurer', 'cusip', 'explain', '_UNIQUE_ID'],
            '_ex_dividend_details': ['record_type', 'record_value', '_UNIQUE_ID'],
            '_special_calls': ['type', 'event', '_UNIQUE_ID'],
            '_tax_status': ['cusip', 'is_bank_qualified', 'is_federal_tax', 'is_state_tax', 'is_amt_tax', 'explain', '_UNIQUE_ID'],
            '_security_code': ['revenue', 'general_obligation', 'exceptions', 'explain', '_UNIQUE_ID'],
            '_capital_purpose': ['value', 'explain', '_UNIQUE_ID'],
            '_use_of_proceeds': ['value', 'explain', '_UNIQUE_ID'],
            '_coupon_step_up_down_schedule': ['cusip', 'start_date', 'end_date', 'interest_rate', 'index_name', 'multiplier', 'spread', 'min_or_floor_interest_rate', 'max_or_ceiling_interest_rate', 'explain', '_UNIQUE_ID'],
            '_series': ['value', 'sub_value', 'full_value','amount', '_UNIQUE_ID'],
            '_bond_label': ['bond_label', 'cusip', "is_premium_pac", '_UNIQUE_ID'],
            '_coupon_payment_terms': ['frequency', 'interest_payment_commencement_date', '_UNIQUE_ID'],
        },
        'muni_ca': {
            '_agent_type': ['agent_name', 'agent_type', '_UNIQUE_ID'],
            'escrow_details': ['escrow_type', 'escrow_amount', 'escrow_reason', '_UNIQUE_ID'],
            'bond_retirement_table_defeasance': ["orig_cusip","defeased_cusip","non_defeased_cusip","insured_cusip","evt_app_date","mat_date","original_amount","outstanding_amount","defeased_amount","non_defeased_amount", "price", '_UNIQUE_ID'],
            'bond_retirement_table_redemption': ["orig_cusip","insured_cusip","evt_app_date","mat_date","original_amount","outstanding_amount","redemption_amount","price", '_UNIQUE_ID']
        },
        'ipreo_pos': {
            '_maturity_schedule': ["cusip", "maturity_date", "amount", "interest_rate", "price", "yield_pct", "is_term_bond", "coupon_type", "index_name", "multiplier", "spread", "maturity_amount", "interest_rate_2", "_UNIQUE_ID"],
            '_maturity_schedule_cab': ["cusip", 'maturity_date', 'original_issue_amount', 'maturity_amount', 'accretion_rate', 'interest_rate', 'price', 'yield_pct', 'is_term_bond', "coupon_type", "_UNIQUE_ID"],
            '_call_schedule': ['cusip', 'start_date', 'end_date', 'method_type', 'method_sub_type', 'frequency', 'price', 'make_whole_spread', 'make_whole_benchmark', 'max_notice_days', 'min_notice_days', 'anniversary_years', 'maturity_date', "_UNIQUE_ID"],
            '_call_pricing_schedule': ['start_date', 'end_date', 'price', "redemption_type", "is_premium_pac", "maturity_date", "maturity_year", "_UNIQUE_ID"],
            '_put_schedule': ['cusip', 'put_start_date', 'put_type', 'price', 'max_notice_days', 'min_notice_days', "_UNIQUE_ID"],
            '_sink_schedule': ['cusip', 'date', 'amount', 'accreted_value', 'redemption_price', 'sink_sub_type', '_UNIQUE_ID'],
            '_expected_sink_schedule': ['cusip', 'date', 'amount', 'tranche', 'explain', '_UNIQUE_ID'],
            '_accretion_schedule': ['cusip', 'date', 'amount', '_UNIQUE_ID'],
            '_credit_enhancement': ['program_name', 'legal_code','provider_name', 'credit_type', 'expiration_date', 'explain', '_UNIQUE_ID'],
            '_entity_type': ['entity_type', 'entity_name', 'entity_city', 'entity_state', 'explain', '_UNIQUE_ID'],
            '_bond_insurer_mapping': ['bond_insurer', 'cusip', 'explain', '_UNIQUE_ID'],
            '_ex_dividend_details': ['record_type', 'record_value', '_UNIQUE_ID'],
            '_special_calls': ['type', 'event', '_UNIQUE_ID'],
            '_tax_status': ['cusip', 'is_bank_qualified', 'is_federal_tax', 'is_state_tax', 'is_amt_tax', 'explain', '_UNIQUE_ID'],
            '_security_code': ['revenue', 'general_obligation', 'exceptions', 'explain', '_UNIQUE_ID'],
            '_capital_purpose': ['value', 'explain', '_UNIQUE_ID'],
            '_use_of_proceeds': ['value', 'explain', '_UNIQUE_ID'],
            '_coupon_step_up_down_schedule': ['cusip', 'start_date', 'end_date', 'interest_rate', 'index_name', 'multiplier', 'spread', 'min_or_floor_interest_rate', 'max_or_ceiling_interest_rate', 'explain', '_UNIQUE_ID'],
            '_series': ['value', 'sub_value', 'full_value','amount', '_UNIQUE_ID'],
            '_bond_label': ['bond_label', 'cusip', "is_premium_pac", '_UNIQUE_ID'],
            '_coupon_payment_terms': ['frequency', 'interest_payment_commencement_date', '_UNIQUE_ID']
        },
        'pcap': {
            'financials': ['period_type', 'start_date', 'end_date', 'beginning_balance', 'contributions', 'distributions', 'management_fee', 'management_fee_rebate', 'management_fee_type', '_UNIQUE_ID'],
        },
        'es_credit_agreements': {

        },
        'BDC': {

        },
        'DM_BRKR_RESEARCH_REPORTS': {

        },
        'forecast_summary': {
            '_forecast_summary': ["Dec-31-2019", "Dec-31-2020", "Dec-31-2021", "Dec-31-2022", "Dec-31-2023", "Dec-31-2024", "Dec-31-2025", "Dec-31-2026", "_UNIQUE_ID"]
        },
        'private_markets': {
            '_repayment_schedule': ['date', 'amount', '_UNIQUE_ID']
        },
        'CI_FRAC': {},
        'maritime_esg': {},
        'gsac_d': {
            '_maturity_schedule': ["maturity_date", "amount", "issue_currency", "interest_rate", "price", "yield_pct", "coupon_type", "index_name", "spread", "_UNIQUE_ID"],
            '_call_schedule': ['security_context', 'start_date', 'end_date', 'method_type', 'method_sub_type', 'frequency', 'price', 'make_whole_spread', 'make_whole_benchmark', 'max_notice_days', 'min_notice_days', 'anniversary_years', 'maturity_date', "call_trigger_description", "equity_clawback_percent", "notice_days_convention", "_UNIQUE_ID"],
            '_entity_type': ['security_context','entity_type', 'entity_name','_UNIQUE_ID'],
            '_interest_schedule': ["start_date", "end_date", "curreny", "coupon_frequency", "coupon_type","min_coupon", "max_coupon",
                     "cash_rate" ,"reference_rate", "observation_days", "accrual_adjusted_flag", "spread", "benchmark_floor","day_count_convention",
                      "reset_lag_days","index_name", "reset_frequency", "pay_calendar_value", "business_day_convention", "_UNIQUE_ID"],
            'security_context': ["value", "full_value", "amount", "_UNIQUE_ID"],
            '_security_identifiers': ["security_context", "type", "is_temporary", "cusip", "isin",'common_code',"is_accredited_investor", "is_regulation_d", "regulation_s_category", "is_tefra_c", "is_tefra_d", "is_private_placement", "has_144a_registration_right", "cins", "_UNIQUE_ID"],
            'covenants':["is_change_of_control_covenant", "change_of_control_put_value", "_UNIQUE_ID"],
            'pik_schedule':["currency", "cash_rate", "interest_rate", "security_type", "start_date", "end_date", "_UNIQUE_ID"],
            'islamic_sukuk_details': ["sukuk_type", "portfolio", "_UNIQUE_ID"],
            'register_sensitive_details': ["coupon_increase", "start_date", "end_date", "_UNIQUE_ID"],
            'exdividend_record_details': ["exdividend_rules", "exdividend_offset", "record_date_rules", "record_date_offset", "_UNIQUE_ID"],
            'holiday_schedule': ["holiday_calendar", "detail", "_UNIQUE_ID"],
            'selling_restriction_countries': ["country", "_UNIQUE_ID"],
            '_internal': ["offer_to_exchange_issue_date", "offer_to_exchange_indicator", "oid_tax_eligible_desc", "_UNIQUE_ID"],
            '_convertible_or_exchangeable': ["co_co_trigger_description", "convertible_trigger_level", "underlying_security", "underlying_currency", "conversion_details", "price", "ratio", "type", "start_date", "end_date", "result", "fx_rate", "par_value", "_UNIQUE_ID"],
            '_amount_details': ["change_type", "effective_date", "price", "amount", "type", "_UNIQUE_ID"],
            '_asset_exchange': ["operating_exchange", "listing_date", "_UNIQUE_ID"],
            '_clearing_schedule': ["clearing_agency", "_UNIQUE_ID"],
            '_use_of_proceeds':["value", "explain", "_UNIQUE_ID"],
            '_esg_label':["value", "explain", "_UNIQUE_ID"],
            '_esg_standards':["value", "explain", "_UNIQUE_ID"],
            '_esg_use_of_proceeds':["use_of_proceeds","sustainable_development_goals","_UNIQUE_ID"],
            '_esg_providers':["certification_provider", "second_opinion_provider","verification_provider", "_UNIQUE_ID"],
            '_issue_date_schedule':["issue_date", "_UNIQUE_ID"],
            '_extendable_schedule': ["coupon", "day_count","effective_coupon_type", "coupon_frequency", "frequency", "spread", "index_name", "interest_payment_currency", "reference_rate", "start_date", "end_date", "_UNIQUE_ID"],
            '_put_schedule':["price", "max_notice_day", "min_notice_day", "start_date", "end_date", "frequency", "notice_days_convention", "method_sub_type", "method_type", "_UNIQUE_ID"],
            '_organization':["lei", "_UNIQUE_ID"],
            '_sink_schedule':["factor", "type", "frequency", "start_date", "amount", "price", "_UNIQUE_ID"],
            '_instrument_relation':["effective_date", "identifier", "identifier_type", "instrument_relation_type", "_UNIQUE_ID"],
            '_principal_details':["index_name", "exchange_rate", "currency", "payment_frequency", "business_day_convention", "_UNIQUE_ID"],
            '_exchange_offer_details': ["exchange_offer_period",  "_UNIQUE_ID"],
            '_amortization_schedule': ["start_date", "end_date", "amount", "frequency", "pre_payment_factor", "type", "_UNIQUE_ID"],
            '_esg_slb_details':["effective_date","key_performance_indicators", "reference_date", "result", "sustainability_performance_targets", "type", "type_impact", "type_impact_value", "_UNIQUE_ID"],
            '_fallback_rate_schedule':["fallback_rate_applicable","fallback_rate_details", "_UNIQUE_ID"],
           }

    },

    schedules: [
        '_call_pricing_schedule',
        '_agent_type',
        '_call_schedule',
        '_maturity_schedule',
        '_sink_schedule',
        '_special_calls',
        '_maturity_schedule_cab',
        '_put_schedule',
        '_accretion_schedule',
        '_credit_enhancement',
        '_entity_type',
        '_bond_insurer_mapping',
        '_ex_dividend_details',
        '_tax_status',
        '_security_code',
        '_capital_purpose',
        '_use_of_proceeds',
        '_series',
        '_coupon_step_up_down_schedule',
        '_bond_label',
        '_coupon_payment_terms',
        'period_list',
        '_tranche_name',
        '_time_periods',
        'escrow_details',
        '_expected_sink_schedule',
        'bond_retirement_table_defeasance',
        'bond_retirement_table_redemption',
        '_security_identifiers',
        'security_context',
        '_interest_schedule',
        'covenants',
        'pik_schedule',
        'islamic_sukuk_details',
        'register_sensitive_details',
        'exdividend_record_details',
        'holiday_schedule',
        '_internal',
        '_use_of_proceeds',
        '_esg_label',
        '_esg_standards',
        '_esg_use_of_proceeds',
        '_esg_providers',
        '_issue_date_schedule',
        '_extendable_schedule',
        '_organization',
        '_instrument_relation',
        '_principal_details',
        '_instrument_market_name',
        '_exchange_offer_details',
        '_convertible_or_exchangeable',
        '_amount_details',
        '_asset_exchange',
        '_clearing_schedule',
        '_amortization_schedule',
        '_esg_slb_details',
        '_fallback_rate_schedule',
    ]
    ,
    dropDownFormatFileTypes: ['muni_os', 'muni_ca', 'pcap', 'BDC', 'DM_BRKR_RESEARCH_REPORTS','private_markets', 'gsac_d']
    ,
    dropDownFormatKeys: {
        muni_os: ['agent_type', 'state_name', 'escrow_type', 'event_type', 'is_accredited_investor', 'is_new_issue', 'is_callable', 'is_green_bond', 'is_guarantee', 'is_private_placement', 'is_remarketed', 'is_rule_144a', 'has_extraordinary_redemption', 'is_step_up_rate', 'interest_type', 'drawn', 'repayment_type', 'repayment_type', 'is_prepayable', 'is_adjusted_accrual_end_date', 'business_day_convention'],
        muni_ca: [],
        ipreo_pos: ['agent_type', 'escrow_type', 'event_type', 'is_accredited_investor', 'is_new_issue', 'is_callable', 'is_green_bond', 'is_guarantee', 'is_private_placement', 'is_remarketed', 'is_rule_144a', 'has_extraordinary_redemption', 'is_step_up_rate', 'interest_type', 'drawn', 'repayment_type', 'repayment_type', 'is_prepayable', 'is_adjusted_accrual_end_date', 'business_day_convention'],
        private_markets: [],
        Insurance_apac: [],
        Insurance_emea: [],
        pcap: [],
        es_credit_agreements: ['is_public_loan'],
        BDC: [],
        DM_BRKR_RESEARCH_REPORTS: [],
        CI_FRAC: [],
        maritime_esg: [],
        gsac_d: ['bail_in_option', 'denomination_type', 'issue_type', 'issue_sub_type', 'is_mtn_flag', 'is_tax_call', 'structured_note_flag', 'syndicated_issuance_indicator', 'sfc_chapter_37', 'is_toggle',
                'is_bond_linked','is_commodity_linked','is_coupon_deferrable','is_coupon_cumulative', 'is_currency_linked', 'is_trust_preferred',
                'is_perpetual', 'is_post_determined', 'is_ratings_sensitive', 'payment_category', 'payment_category_subtype', 'is_sec_registered']
    },
    qa_submit_authorized_users: ['maxim.dittrich','ryan.morris', 'shailesh.patait', 'harsha.magaji', 'rajni.fanda','kasshyap.p', 'shambhavi.singh2',
                                   'ali.lotfdar', 'john.mo', 'madan.deosaran', 'adrian.st-john','ankit.aggarwal2', 'shivank.agarwal', 'neha.goyal',
                                   'sameer.ahuja','prachi.bisht','biswajit.dandapat','ipshita.dixit','akul.gupta2','rishu.jain',
                                   'ranadheer.jonnalaga','lokesh.kapoor','muskan.mahajan1','jahnavi.mendiratta','jyoti.narula',
                                   'kasshyap.p','vidhya.paswan','shailesh.patait','mansi.pathak','yash.purohit','rahul.rajora',
                                   'kalpana.sani','kumar.sanu','prabhjot.singh','shambhavi.singh2','shashi.singh','shivani.singh8',
                                   'vishnu.vardhan','shalini.verma','reetu.yadav','gourav.arora','rajni.fanda','prerna.goel',
                                   'atisha.jain','nirvika.jain','shivali.kamal','neha.kumari3','nitin.lakra','gaurav.pathak',
                                   'kratika.suri','preeti.tiwari2','darshika.verma','sakshi.namdev','sunilkumar.sahoo', 'amit.grover', 'abraham.hardjana',
                                   'srikanth.sharman','nishant.jain3','gaurav.agarwal2']
    ,
    prod_submit_authorized_users: ['maxim.dittrich','ryan.morris', 'shailesh.patait', 'harsha.magaji', 'rajni.fanda','kasshyap.p', 'shambhavi.singh2',
                                    'sameer.ahuja','prachi.bisht','biswajit.dandapat','ipshita.dixit','akul.gupta2','rishu.jain',
                                    'ranadheer.jonnalaga','lokesh.kapoor','muskan.mahajan1','jahnavi.mendiratta','jyoti.narula',
                                    'kasshyap.p','vidhya.paswan','shailesh.patait','mansi.pathak','yash.purohit','rahul.rajora',
                                    'kalpana.sani','kumar.sanu','prabhjot.singh','shambhavi.singh2','shashi.singh','shivani.singh8',
                                    'vishnu.vardhan','shalini.verma','reetu.yadav','gourav.arora','rajni.fanda','prerna.goel',
                                    'atisha.jain','nirvika.jain','shivali.kamal','neha.kumari3','nitin.lakra','gaurav.pathak',
                                    'kratika.suri','preeti.tiwari2','darshika.verma','sakshi.namdev','sunilkumar.sahoo', 'srikanth.sharman']
    ,
    qa_unlock_authorized_users: ['maxim.dittrich','ryan.morris', 'shailesh.patait', 'harsha.magaji',
                                    'ali.lotfdar', 'john.mo', 'madan.deosaran', 'adrian.st-john','ankit.aggarwal2', 'shivank.agarwal',
                                    'amit.grover', 'abraham.hardjana','rajni.fanda','kasshyap.p', 'shashi.singh', 'shivali.kamal']
    ,
    prod_unlock_authorized_users: ['maxim.dittrich','ryan.morris', 'shailesh.patait', 'harsha.magaji', 'rajni.fanda','kasshyap.p',
                                    'adrian.st-john','shashi.singh', 'shivali.kamal']
    ,
    qa_file_upload_authorized_users: ['maxim.dittrich','ryan.morris', 'shailesh.patait', 'harsha.magaji',
        'ali.lotfdar', 'john.mo', 'madan.deosaran', 'adrian.st-john','ankit.aggarwal2', 'shivank.agarwal',
        'amit.grover', 'abraham.hardjana','rajni.fanda','kasshyap.p','nishant.jain3','nishant.jain3','gaurav.agarwal2']
    ,
    prod_file_upload_authorized_users: ['harsha.magaji', 'adrian.st-john', 'shailesh.patait', 'shashi.singh', 'kasshyap.p',
                                        'rajni.fanda','shivali.kamal', 'prabhjot.singh']
    ,
    context_attribute:  {
                            muni_os: '_series',
                            ipreo_pos: 'series',
                            es_credit_agreements: 'facility_name',
                            DM_BRKR_RESEARCH_REPORTS: 'company',
                            private_markets: '_facility',
                            BDC: 'industry',
                            CI_FRAC: 'treatment',
                            gsac_d: 'security_context'
    },
    context_attribute_fields: ['value','sub_value','full_value']
    ,
    subContext_attribute: {
                            muni_os: '_bond_label'
    }
    ,
    show_context_file_types:  ['muni_os', 'ipreo_pos', 'es_credit_agreements', 'DM_BRKR_RESEARCH_REPORTS', 'BDC', 'private_markets', 'CI_FRAC', 'gsac_d']
    ,
    show_subcontext_file_types:  ['muni_os']
    ,
    show_subcontext_attributes: {
        muni_os: ['_call_pricing_schedule', '_coupon_payment_terms']
    }
    ,
    attribute_empty_values: ['Not Found', '']
    ,
    invalid_context: [null, '']

};