import { isTable } from '../RightPanel/TableAttributesUtil.js';
import { addAttributeKeyToChangedAttributeRef } from '../RightPanel/ChangedAttribute.js';

export function updateContextSubContext(key, updatedMap, index, currentValue, newValue, changedAttributesRefCurrent) {
    let attribute = updatedMap.get(key);
    
    if ( !Array.isArray(attribute) ){
        return;
    }

    if ( attribute.length < index ){
        return;
    }

    if (!isTable(attribute) && attribute[0] === 'Not Found'){
        return;
    }

    if ( attribute[index] === currentValue || attribute[index] === undefined || attribute[index] === '_NF' || attribute[index] === null){
        changeContextSubContext(attribute, index, newValue, key, changedAttributesRefCurrent);
    }
}

export function changeContextSubContext(attribute, index, newValue, key, changedAttributesRefCurrent) {
    attribute[index] = newValue;
    addAttributeKeyToChangedAttributeRef(key, changedAttributesRefCurrent);
}