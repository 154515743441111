import gtConfig from '../../config.js';
import { lockUnluckSelectedDocument, getPathfinderJobs } from '../../services.jsx';

export async function unlockDocument(authState, oktaAuth, document){
    const url = gtConfig.ground_truth_back_end.lockDocument + "?file_name=" + document  +  "&unlock=" + true ;
    const unlock = async (authState, oktaAuth, url) => {
        try{
            const result = await lockUnluckSelectedDocument(authState, oktaAuth, url);
            return result;
        } catch (error) {
            console.error('Error in Unlock document:', error);
            throw error;
        }
    }
    return await unlock(authState, oktaAuth, url);

}

export async function lockDocument(authState, oktaAuth, document){
    const url = gtConfig.ground_truth_back_end.lockDocument + "?file_name=" + document + "&unlock=" + false ;
    const lock = async (authState, oktaAuth, url) => {
        try{
            const result = await lockUnluckSelectedDocument(authState, oktaAuth, url);
            return result;
        } catch (error) {
            console.error('Error in lock document:', error);
            throw error;
        }
    }
    return await lock(authState, oktaAuth, url);
}


export async function getDocumentJobInfo(authState, oktaAuth, document, is_validation){
   // check if document is unlock or lock by current User 
   const url = gtConfig.ground_truth_back_end.getJob + "?file_name=" + document + "&is_validation=" + is_validation ;
   const getJob = async (authState, oktaAuth, url) => {
       try{
          const result =  await getPathfinderJobs(authState, oktaAuth, url);
          return result;
       } catch (error) {
           console.error('Error in get user of locked document:', error);
       }
   }
  return await getJob(authState, oktaAuth, url);
}


export function getLockerUserName(result){
    let username = ''
    if (result && result.response && result.response.list && result.response.list.length !== 0){
        const job = result.response.list[0];
        username = job.username;
    }
    return username;
}
