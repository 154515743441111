import {v4 as uuidv4} from "uuid";
import gtConfig from '../../../src/config.js';

export function getScheduleInitialValues(fileType, scheduleName, contextAttribute, attributeSubContext){
    const data = prepareInitialSchedule(fileType, scheduleName, contextAttribute, attributeSubContext);
        if ( data  == null){
            console.log("Schedule for selected file_type does not exist.")
        } else {
            return data;
        }
}


function prepareInitialSchedule(fileType, scheduleName, contextAttribute, attributeSubContext){
    let fields = null;
    let fileTypeSchedules =  gtConfig.schedule_fields[fileType];
    
    if ( fileTypeSchedules == null && gtConfig.schedule_fields['muni_os'] == null){
        console.log(`Schedule for FileType ${fileType} and muni_os does not exist.`)  
        return null;
    } else if ( fileTypeSchedules == null && gtConfig.schedule_fields['muni_os'] != null){
        console.log(`Schedule for FileType ${fileType} does not exist, so use muni_os field.`)
        fileTypeSchedules =  gtConfig.schedule_fields['muni_os'];
    } else {
        fields = gtConfig.schedule_fields[fileType][scheduleName];
    }
   
    if ( fields == null){
        return null;
    }

    const initRecord = [];
    initRecord.push([fields]);
    
    const initValues = [];
    for (let field of fields){
        if ( field  !== "_UNIQUE_ID") {
            initValues.push([null, `${field}[0]`]);
        } else {
            initValues.push([uuidv4()])
        }
    }

    initRecord[0].push(initValues);
    initRecord.push("",0,null,"NA","","","","",contextAttribute,attributeSubContext,"");

    return initRecord;
}