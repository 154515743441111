import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../../App.css';
import ExtractionConfirmedContent from './ExtractionConfirmedContent.jsx'
import DistributionContent from './DistributionContent.jsx'
import {Button, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import { useOktaAuth } from '@okta/okta-react';
import { getDocumentJobInfo, getLockerUserName, lockDocument, unlockDocument } from './PathFinder.js';
import OcrTextView from './OcrTextView.jsx';
import fetchDataFileUrl from '../../services.jsx';
import gtConfig from './../../config.js';

const RightPanel = ({
                        selectedFile,
                        onKeySelect,
                        extractedValues,
                        extractedValuesOriginRef,
                        updateExtractedValues,
                        handleAddNewAttribute,
                        handleRestoreAttribute,
                        category,
                        file_type,
                        handleClearInput,
                        isLoading,
                        headerHeight,
                        changedAttributesRef,
                        handleOpenSnackBar,
                        showSnackBar,
                        confirmedBy,
                        confirmededDate,
                        savedBy,
                        savedDate,
                        lastModified,
                        isPathFinderEnable
                    }) => {

    const rightPanelDivRef = useRef(null);
    const [editMode, setEditMode] = useState(false);
    const [isUserEligibleToEdit, setIsUserEligibleToEdit] = useState(false);
    const [taskOwner, setTaskOwner] = useState(null);
    const [isEditingProcessing, setEditingProcessing] = useState(false);
    const [isUnlockProcessing, setUnlockProcessing] = useState(false);
    const [showOcr] = useState(false);
    const [ocrValue] = useState('');
    const [validationIssues, setValidationIssues] = useState(null);

    const { authState, oktaAuth } = useOktaAuth();
    const theme = useTheme();

    useEffect(() => {
       // setEditMode(false);        
        setEditMode(!isPathFinderEnable);
    }, [selectedFile, isPathFinderEnable]);

    async function getTaskOwner(selectedFile){
        const getJobs = async (authState, oktaAuth, selectedFile) => {
            try {
              const result = await getDocumentJobInfo(authState, oktaAuth, selectedFile, false);
              const username =  getLockerUserName(result);
              setTaskOwner(username);
              return  username;
            } catch (error) {
                setTaskOwner('');
                console.error(error);
            }
        };
         return await getJobs(authState, oktaAuth, selectedFile);    
    };
    
    useEffect(() => {
        if (selectedFile){
            getTaskOwner(selectedFile).then(owner => {
                const currentUserId = authState.idToken.claims.UserId;
                const isUserEligible = (currentUserId === owner || owner === null || owner === '');
                setIsUserEligibleToEdit(isUserEligible);
            }).catch(error => {
                console.error('Failed to get task owner:', error);
            });
            fetchValidationIssues(selectedFile, authState, file_type, lastModified, oktaAuth);
        }
    }, [selectedFile]);
    //}, [authState, fetchValidationIssues, file_type, getTaskOwner, lastModified, oktaAuth, selectedFile]);

    const fetchValidationIssues = useCallback((selectedFile, authState, file_type, lastModified, oktaAuth) => {
        const fetchAttributeValidationIssues = async () => {
            try {
              const lastModifiedDate = lastModified ? lastModified.split(" ")[0] : "";
              const url =
                gtConfig.ground_truth_back_end.attribute_validation_issues +
                "?file_type=" +
                file_type +
                "&file_name=" +
                selectedFile +
                "&lastModified=" +
                lastModifiedDate;
              const result = await fetchDataFileUrl(url, authState, oktaAuth);
              if (result && !result.success){
                  console.log(result.error);
                  setValidationIssues(null);
              } else {
                setValidationIssues(result.data);
              }
            } catch (error) {
              console.error("Error fetching Validation issue Data:", error);
              setValidationIssues([]);
            }
        }
        if (lastModified){
            fetchAttributeValidationIssues();
        }
        
       },[])

    function  isUnlockDisable(){
        const currentUserId = authState.idToken.claims.UserId;
        return (taskOwner !== currentUserId) || isUnlockProcessing;
    }

    function onClickEditMode(){
        if (!isPathFinderEnable){
            setEditMode(true);
            return;
        }

        const getJobs = async (authState, oktaAuth, selectedFile) => {
            const currentUserId = authState.idToken.claims.UserId;
            try {
              const result = await getDocumentJobInfo(authState, oktaAuth, selectedFile, false);
              const username = getLockerUserName(result);
              if (username === ''){
                try {
                    setEditingProcessing(true);
                    const result = await lockDocument(authState, oktaAuth, selectedFile);
                    if (result.success === true){
                        setEditMode(true);
                        setTaskOwner(currentUserId);
                        showSnackBar(`Document locked by ${currentUserId}`, 'success');
                    } else {
                        showSnackBar(result.message, 'error');
                    } 
                    
                } catch (error) {
                    showSnackBar(`Error in locking document: ${error} `, 'error');
                    console.error(error);
                }
              } else if ( username === currentUserId){
                setEditMode(true);
              } else {
                setEditMode(false);
                showSnackBar(`Document has already locked by ${username}`, 'warning');
              }
            } catch (error) {
                showSnackBar(`Error in locking document: ${error} `, 'error');
                console.error(error);
            } finally {
                setEditingProcessing(false);
            }
        };

        getJobs(authState, oktaAuth, selectedFile);
    }

    async function onClickUnlockDocument(){
        setUnlockProcessing(true);
        try{
            const unlock = async (authState, oktaAuth, selectedFile) => {
                const result =  await unlockDocument(authState, oktaAuth, selectedFile);
                if (result.success === true){
                    setEditMode(false);
                    setTaskOwner('');
                    const currentUserId = authState.idToken.claims.UserId;
                    showSnackBar(`Document Unlocked by ${currentUserId}`, 'success');
                } else {
                    showSnackBar(result.message, 'error');
                }
              };
              return await unlock(authState, oktaAuth, selectedFile);
        } catch (error) {
            showSnackBar(`Error while unlocking document: ${error} `, 'error');
        } finally {
            setUnlockProcessing(false);
        }
    }

    return (
        <div className="right-panel" ref={rightPanelDivRef} style={{ backgroundColor: theme.palette.background.paper}}>  
            <header style={{position: 'sticky', top: '0', marginTop: 0 ,marginBottom: '10px', minHeight: '50px',textAlign: 'center',
                     backgroundColor: theme.palette.background.paper, zIndex: 1000}}>
                {selectedFile && <div style={{marginBottom: '3px'}}>
                <Typography variant='h6' sx={{fontSize: "0.95rem", marginBottom: "5px", color: theme.palette.text.secondary}}>Predicted Ground Truth with Corroboration</Typography>
               { !showOcr && isPathFinderEnable && <Button 
                    onClick={onClickEditMode} 
                    variant='outlined' 
                    size='small'
                    sx={{height: '20px', marginRight: '3px'}}
                    disabled={editMode || !isUserEligibleToEdit || isEditingProcessing}
                > 
                    <Typography title='Edit' variant='body2' > Edit </Typography>
                </Button>
                }
               {!showOcr && isPathFinderEnable && <Button 
                    onClick={onClickUnlockDocument}  
                    variant='outlined'
                    size='small' 
                    sx={{height: '20px'}}
                    disabled={isUnlockDisable()}
                >
                    <Typography title='Unlock Document' variant='body2' > Unlock Document </Typography>
                </Button> }
                &nbsp;
                { !showOcr && taskOwner && isPathFinderEnable && <label> 
                    <Typography variant='caption' sx={{color: theme.palette.text.secondary}}>Locked by {taskOwner}</Typography>
                </label>}
                </div>}
                { (isEditingProcessing || isUnlockProcessing) && 
                    <div> 
                        <Typography variant='caption' sx={{ color: theme.palette.text.secondary }}
                        > 
                            {isEditingProcessing ? 'Lock' : 'Unlock'} Document is processing...
                        </Typography>
                    </div>
                }
            </header>
            <div  ref={rightPanelDivRef}>
                {! showOcr && (() => {
                    switch (category) {
                        case 'distribution':
                            return <DistributionContent selectedFile={selectedFile}
                                                        extractedValues={extractedValues}
                            />
                        case 'extraction':
                        case 'gt_ops_confirmed':
                            return <ExtractionConfirmedContent selectedFile={selectedFile}
                                                            extractedValuesOriginRef={extractedValuesOriginRef}
                                                            extractedValuesMain={extractedValues}
                                                            handleAddNewAttribute={handleAddNewAttribute}
                                                            handleRestoreAttribute={handleRestoreAttribute}
                                                            onKeySelect={onKeySelect}
                                                            updateExtractedValues={updateExtractedValues}
                                                            category={category}
                                                            file_type={file_type}
                                                            handleClearInput={handleClearInput}
                                                            isLoading={isLoading}
                                                            headerHeight={headerHeight}
                                                            changedAttributesRef={changedAttributesRef}
                                                            editMode={editMode}
                                                            setEditMode={setEditMode}
                                                            showSnackBar={showSnackBar}
                                                            setTaskOwner={setTaskOwner}
                                                            confirmedBy={confirmedBy}
                                                            confirmededDate={confirmededDate}
                                                            savedBy={savedBy}
                                                            savedDate={savedDate}
                                                            isPathFinderEnable={isPathFinderEnable}
                                                            validationIssues={validationIssues}

                            />
                        default:
                            return null
                    }
                })()}
                {showOcr && 
                    <OcrTextView 
                        ocrValue={ocrValue}
                    />
                }
            </div>
        </div>

    );
};

export default RightPanel;
