import { removeIndexFromLabel } from "../../Utils";
import { isTable, isTableValid } from "./TableAttributesUtil";
import gtConfig from '../../config.js';


export function dataAreValidToConfirm(extractedValues){
    // Check if single attribute values are empty
    for (const [, data] of extractedValues) {
        const values = data[0];
        if ( !values || values === ''){
            return false
        }
    }
    return true;
}


export function removeEmptyScheduleRows(extractedValues){
    const newMap = new Map();
    for (let attribute of extractedValues) {
        const isScheduleAttribute = Array.isArray(attribute) && attribute.length > 0 && isTable(attribute[1]);
       
        if ( isScheduleAttribute) {
            const scheduleLength = attribute[1][0].length;
            const newValues = [attribute[1][0][0]]; // add the header
            for (let i = 1; i < scheduleLength; i++){
                const isRowEmpty = attribute[1][0][i].slice(0, -1).every(arr => arr[0] === null || arr[0] === '' || arr[0].length === 0)
                if (!isRowEmpty){
                    newValues.push(attribute[1][0][i]);
                }
            }
            if (newValues.length === 1){ // It just has the header
                continue;
            }
            attribute[1][0] = newValues;
            newMap.set(attribute[0], attribute[1]);
        } else {
            newMap.set(attribute[0], attribute[1]);
        }
    }

    return newMap;
}

/*
* Remove all the attributes that has ('Not Found') value with empty/null Context.
*/
export function removeInvalidAttributes(extractedValues){
    const newMap = new Map();
    for (let attribute of extractedValues){
        if ( emptySingleAttribute(attribute)){
           continue
        }
        newMap.set(attribute[0], attribute[1]);
    }

    return newMap;

    function emptySingleAttribute(attribute) {
        const contextIndex = 9;
        return Array.isArray(attribute) && attribute.length > 1 
               && Array.isArray(attribute[1]) 
               && attribute[1].length > 9
               && gtConfig.attribute_empty_values.includes(attribute[1][0]) 
               && gtConfig.invalid_context.includes(attribute[1][contextIndex]);
    }
}

export function isTableValidToBeConfirm(values){
    return isTableValid(values);
}

export function unconfirmRevertAllAttributes(attributeKey, confirmedKeys, extractedValuesOriginRef){
    const attribute = removeIndexFromLabel(attributeKey);
    const originKeys= Array.from(extractedValuesOriginRef.keys());
    const keys = originKeys.filter(key => key.startsWith(attribute));
    for (let key of keys){
        unconfirmRevertAttributes(key, confirmedKeys, extractedValuesOriginRef)
    }
}

export function unconfirmRevertAttributes(attributeKey, confirmedKeys, extractedValuesOriginRef){
    const attributeValues = extractedValuesOriginRef.get(attributeKey);
    if (isTable(attributeValues)){
       for ( let i = 1; i < attributeValues[0].length; i++){
        const index = attributeValues[0][i][attributeValues[0][i].length - 1][0];
            if (confirmedKeys.has(index)){
                confirmedKeys.delete(index);
            }
       }
       confirmedKeys.delete(attributeKey);
    } else {
        confirmedKeys.delete(attributeKey);
    }
}
