import React, {useEffect, useState, useCallback, useRef, lazy, Suspense} from 'react';
import './App.css';
import {useOktaAuth} from '@okta/okta-react';
import Stack from '@mui/material/Stack';
import LeftPanel from './pages/LeftPanel/LeftPanel.jsx';
import MiddlePanel from './pages/MiddlePanel/MiddlePanel.jsx';
import RightPanel from './pages/RightPanel/RightPanel.jsx';
import Login from './pages/Login';
import Header from './pages/Header/Header.jsx';
import gtConfig from './config.js';
import {v4 as uuidv4} from 'uuid';
import fetchDataFileUrl, { downloadPdf, fetchPdfRedirectUrl } from './services';
import { removeIndexFromLabel} from './Utils.js'
import {
    getScheduleInitialValues,
} from './pages/RightPanel/ScheduleInitialValues.js'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useParams } from "react-router";
import { Box, useTheme } from '@mui/material';
import { isScheduleEmpty } from './pages/RightPanel/TableAttributesUtil.js';


const AttributesModal = lazy(() =>
    import('./pages/AttributesModal')
);

const RestoreAttributesModal = lazy(() =>
    import('./pages/RestoreAttributesModal')
);


const UNIQUE_ID = "_UNIQUE_ID";
const EXTRACTION_CATEGORY = 'extraction'

const Home = () => {
    const { platform, doc_type, documentName, mode} = useParams();
    const [isExternalUse, setIsExternalUse] = useState(false);
    const [externalDetails, setExternalDetails] = useState({});
    
    const theme = useTheme();

    useEffect(() => {
    if (platform !== null && platform !== undefined && platform !== '') {
            setIsExternalUse(true);
            setExternalDetails({platform: platform, doc_type: doc_type, documentName: documentName, themeMode: mode});
        }
    }, [platform]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRestoreAttributeModalOpen, setIsRestoreAttributeModalOpen] = useState(false);
    
    const [state, setState] = useState({
        selectedKey: null,
        selectedFile: null,
        jsonLabels: null,
        jsonCoords: {},
        jsonCoordsTuples: {},
        extractedValues: [],
        confirmedBy: null,
		lastModified: null,
        submittedBy:null,
        submittedDate:null,
        savedBy:null,
        savedDate:null,
        totalFieldsConfirmed: 0,
        file_type: null,
        category: ''
    });
    const [userInfo, setUserInfo] = useState(null);
    const currentAttributes = useRef(new Set());
    const attributeContext = useRef(new Set());
    
    const selectedTextRef = useRef([]);

    const selectingDivsRefs = useRef(new Set()); // keeps divs which are seleting[by moving the mouse for selected element]
    const [pdfDoc, setPdfDoc] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchFeatureChecked, setSearchFeatureChecked] = useState(true);
    const [isPathFinderEnable, setPathFinderEnable] = useState(true);
    const [textDivs, setTextDivs] = useState(new Map());
    const [divIdText, setDivIdText] = useState(new Map());
    const [navOrderBy, setNavOrderBy] = React.useState('extraction');

    const textDivRefs = useRef(new Map());
    const [fontValue, setFontValue] = useState(gtConfig.defaultOcrFontSize);

    /*
    * Map<pageNum, {'scale': scale, 'padding': padding, 'top':  topConvasClone, 'left': leftCanvasClone,'canvas': canvas}
    * to keep detail of canvas elements for ocr type
    */
    const canvasMapForOcrRefs = useRef(new Map());

    const extractedValuesOriginRef = useRef(new Map()); // new Map<String, Set>

    const changedAttributesRef = useRef(new Map());

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState('');
    
    const {authState, oktaAuth} = useOktaAuth();

    const [isValidationIssueModalOpen, setValidationIssueModalOpen] = useState(false);
    const [isLockDocumentModalOpen, setLockDocumentModalOpen] = useState(false);

    const headerHeight = 60
    
    // useEffect(() => {
    //     console.log("First Render Home component.");
    //     return () => {
    //         // Cleanup function to clear the ref on unmount
    //         console.log("Cleaning Home component.");
    //         canvasMapForOcrRefs.current = null;
    //         pdfContainerRef.current = null;
    //         tuplesDivIdsRefs.current = null;
    //         redOcrDivRefs.current = null;
    //         noOcrDivRefs.current = null;
    //         extractedValuesOriginRef.current = null;
    //         changedAttributesRef.current = null;
    //         currentAttributes.current = null
    //         attributeContext.current = null;
    //         selectingDivsRefs.current = null;
    //     };

    // }, []);
   
     
    const handleOpenModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const handleOpenRestoreAttributeModal = useCallback(() => {
        setIsRestoreAttributeModalOpen(true);
    }, []);

    const handleCloseRestoreAttributeModal = useCallback(() => {
        setIsRestoreAttributeModalOpen(false);
    }, []);

    const [middlePanelContentType, setMiddlePanelContentType] = useState('pdf'); // 'pdf' or 'ocr'
    const [triggerMiddlePanelContentType, setTriggerMiddlePanelContentType] = useState(false);


    useEffect(() => {
        if (searchFeatureChecked === false){
            resetSearchFeatureValues();
        }
    }, [searchFeatureChecked]);

    const getPathfinderActiveStatus = async(authState, oktaAuth, gtConfig, setPathFinderEnable) => {
        const fetchData = async () => {
            try {
                if (authState == null || oktaAuth == null){
                    return;
                }
                const url = gtConfig.ground_truth_back_end.pathfinder_active_status
                const response = await fetchDataFileUrl(url, authState, oktaAuth);
                setPathFinderEnable(response?.success === true && response.response === true);
            } catch (error) {
                console.error('Error in getting pathfinder active status:', error);
                setPathFinderEnable(false);
            }
        };

        fetchData();
    };
    const resetSearchFeatureValues = () => {
        setTextDivs(new Map());
        setDivIdText(new Map());
    }

    useEffect(() => {
        const isAuthenticated = authState?.isAuthenticated;
        if (!isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            });
            getPathfinderActiveStatus(authState, oktaAuth, gtConfig, setPathFinderEnable);
        }
    }, [authState, oktaAuth]); // Update if authState changes

    const updateExtractedValues = (values) => {
        currentAttributes.current =  new Set([...values.keys()].map(key => removeIndexFromLabel(key)));
        setState((prevState) => ({
            ...prevState,
            'extractedValues': values,
        }));
    }

    const handleAtomicUpdate = (updatedProperties) => {
        setState((prevState) => ({
            ...prevState,
            ...updatedProperties,
        }));
    };

    const handleKeySelection = (key) => {
        handleAtomicUpdate({selectedKey: key});
    };

    useEffect(() => {
        getNavData(navOrderBy);
    }, [authState, oktaAuth]);

    useEffect(() => {
        getNavData(navOrderBy);
    }, [navOrderBy]);

    const getNavData = useCallback((navOrderBy) => {
        const fetchData = async () => {
            try {
                if (authState == null || oktaAuth == null){
                    return;
                }

                const url = gtConfig.ground_truth_back_end.navFilesUrl + "?orderBy=" + navOrderBy;
                const jsonLabelsMuni = await fetchDataFileUrl(url, authState, oktaAuth);
                const jsonLabels = {...jsonLabelsMuni};
                handleAtomicUpdate({jsonLabels: jsonLabels});
            } catch (error) {
                console.error('Error fetching JSON data:', error);
            }
        };

        fetchData();
    },[authState, oktaAuth]);

    const extractionFileUrlRequest = async (file_type, category, file) => {
        const extractionFileUrl = gtConfig.ground_truth_back_end.fileDataUrl + "?file_type=" + file_type + "&category=" + category + "&file_name=" + file
        return await fetchDataFileUrl(extractionFileUrl, authState, oktaAuth);
    };


    const loadPdfFileRequest = async (file) => {
        if (window.location.href.includes("http://localhost")) {
            const pdfjsLib = window.pdfjsLib;
            pdfjsLib.GlobalWorkerOptions.workerSrc = gtConfig.pdfjslib_global_worksrc;
            const pdfUrl = window.location.href.split('/').slice(0, 3).join('/') + '/pdfs/' + file;
            return await pdfjsLib.getDocument({url: pdfUrl}).promise;
        }

        const pdfRedirectUrl = gtConfig.ground_truth_back_end.signedPdfUrl + "?no_redirect&file_name=" + file;
        try{
            const redirectUrl  = fetchPdfRedirectUrl(pdfRedirectUrl, authState, oktaAuth);
            
            if (redirectUrl === undefined){
                throw new Error('error while getting pdf file')
            }
            let pdfUrl = await redirectUrl;

            const pdfjsLib = window.pdfjsLib;
            const pdfBlob =  await downloadPdf(pdfUrl);
            pdfjsLib.GlobalWorkerOptions.workerSrc = gtConfig.pdfjslib_global_worksrc;
            return await pdfjsLib.getDocument({ data: pdfBlob }).promise;
        } catch (error) {
            console.error(error);
        }
    };
    

    // fetch extracted values
    const handleFileSelect =  async (file, file_type, category) => {
        canvasMapForOcrRefs.current = new Map();
        setFontValue(gtConfig.defaultOcrFontSize);
        resetSearchFeatureValues();
        
        handleAtomicUpdate({extractedValues: new Map()});            
        changedAttributesRef.current = new Map(); // reset changedAttributesRef when user select a file

        setIsLoading(() => true);
        try{
            const [extractedFileData, pdfDoc] = await Promise.all([extractionFileUrlRequest(file_type, category, file),  loadPdfFileRequest(file)]);
            addDefaultValueToToEmptySchedules(extractedFileData.extractedValues, file_type);
            addIndexToArrayObjects(extractedFileData.extractedValues);
            let extractedFileValues = createKeyValArray(extractedFileData.extractedValues);
            const jsonCoordsTuples = {
                ...extractedFileData.jsonCoords_tuples_automation
            }
        
            const jsonCoords = {...extractedFileData.jsonCoords_automation};
            const confirmedBy = extractKeyValue(extractedFileValues, 'confirmedBy');
            const confirmedDate = extractKeyValue(extractedFileValues, 'confirmedDate');
            const savedBy = extractKeyValue(extractedFileValues, 'savedBy');
            const savedDate = extractKeyValue(extractedFileValues, 'savedDate');
            const lastModified = extractedFileData.last_modified;
            
            provideAttributeContext(extractedFileValues, attributeContext);
            
            extractedFileValues = removeExtraKeysAndAddRequiredKeys(extractedFileValues, file_type, category);
            extractedValuesOriginRef.current = structuredClone(extractedFileValues);
        
            handleAtomicUpdate({
                extractedValues: extractedFileValues, 
                jsonCoordsTuples: jsonCoordsTuples,
                jsonCoords: jsonCoords,
                selectedFile: file, 
                file_type: file_type, 
                category: category,
                lastModified: lastModified,
                confirmedBy: confirmedBy, 
                confirmedDate: confirmedDate,
                savedBy:savedBy,
                savedDate:savedDate
            })
            setPdfDoc(pdfDoc);
        } catch(error){
            console.error(error);
        } finally {
            setIsLoading(() => false);
        }
    };
        
    const provideAttributeContext = (extractedValues, attributeContext) => {
        attributeContext.current = new Set();

        // Read from Contexts
        for (let item of extractedValues){
            // Index is located in index = 0 
            if (Array.isArray(item) && item.length > 1) {
                const key = removeIndexFromLabel(item[0]);

                // context is located in index = 9 of item[1]
                if ( gtConfig.schedules.includes(key) && item[1].length > 8 && item[1][9] !== '' && item[1][9] ) { 
                    attributeContext.current.add(item[1][9]);
                }
            }
        }
    };

    const extractKeyValue = (extractedValues, key) => {
        if (extractedValues.has(key)) {
            return extractedValues.get(key);
        }
        return null;
    };

    const addIndexToArrayObjects = (extractedValues) => {
        for (const key in extractedValues) {
            if (extractedValues[key] && Array.isArray(extractedValues[key]) && Array.isArray(extractedValues[key][0])) {
                const elements = extractedValues[key][0];
                const length = elements.length;
                let firstAdd = false;
                for (let i = 0; i < length; i++) {
                    const headerArray = elements[0];
                    // check if pushed "_UNIQUE_ID" before
                    if (headerArray[headerArray.length - 1] === UNIQUE_ID && !firstAdd) {
                        return;
                    }

                    const uniqueId = uuidv4();
                    if (i === 0) { // don't add array type to the header
                        elements[i].push(UNIQUE_ID);
                        firstAdd = true;
                    } else {
                        elements[i].push([uniqueId]);
                    }

                }
            }
        }
    };

    const createKeyValArray = (json) => {
        return new Map(
            Object.entries(json)
            //.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        );
    };

    const handleInputChange = (updatedMap) => {
        handleAtomicUpdate({extractedValues: updatedMap});
    };

    const handleAddNewAttribute = (() => {
        handleOpenModal();
    });

    const handleRestoreAttribute = (() => {
        handleOpenRestoreAttributeModal();
    });

    const handleRestoreAttributeModalSubmit = (attribute) => {
        if (!attribute || attribute === '') {
            handleCloseRestoreAttributeModal();
            return;
        }

        const extractedValuesMap = structuredClone((state.extractedValues));
        const newMap = new Map();
    
        for (let key of extractedValuesOriginRef.current.keys()){
            if (extractedValuesMap.has(key) ){
                newMap.set(key, extractedValuesMap.get(key));
            } else {
                if (key.startsWith(attribute) ){
                    newMap.set(key, structuredClone(extractedValuesOriginRef.current.get(key)));
                }
            }
        }

        setState((prevState) => ({
            ...prevState,
            'extractedValues': newMap,
        }));

        handleCloseRestoreAttributeModal();
    }


    const handleAttributeModalSubmit = (newAttr, contextAttribute) => {
        if (!newAttr || newAttr === '') {
            handleCloseModal();
            return;
        }
        const newMap = new Map(state.extractedValues);
        const scheduleName = removeIndexFromLabel(newAttr);
        const uniqueId = uuidv4();
        const key =  `${newAttr}[${uniqueId}]`
        if ( gtConfig.schedules.includes(scheduleName)) {
           const newScheduleInitialValue = getScheduleInitialValues(state.file_type, scheduleName, contextAttribute);
            if (newScheduleInitialValue == null){
                 console.log('Schedule Initial value does not exist');
                 return;
            } else {
                newMap.set(key, newScheduleInitialValue);    
            }
        } else {
            newMap.set(key, ["Not Found", "", "", "", "NA", "", "", "", "", contextAttribute, "", ""]);
        }
        setState((prevState) => ({
            ...prevState,
            'extractedValues': newMap,
        }));

        currentAttributes.current.add(newAttr);
        handleCloseModal();
    };

    /**
     * ADO-73688: Add default values to empty schedules when attribute exists but the header and body are empty array
     */
    const addDefaultValueToToEmptySchedules = (extractedValues, file_type) => {
        for (const key in extractedValues) {
            const attribute = removeIndexFromLabel(key);
            if (gtConfig.schedules.includes(attribute) && isScheduleEmpty(extractedValues[key]) ){
                const newScheduleInitialValue = getScheduleInitialValues(file_type, attribute);
                if (newScheduleInitialValue == null){
                    console.log(`Schedule Initial value does not exist for attribute ${attribute} in file_type ${file_type}`);
                } else {
                    extractedValues[key] = newScheduleInitialValue;    
                }
            }
        }
    }

    const removeExtraKeysAndAddRequiredKeys = (extractedFileValues, file_type, category) => {
        const all_attributes = gtConfig.attributes_to_display;
        currentAttributes.current = new Set();
    
        let attributes = [];
        if (all_attributes.hasOwnProperty(file_type)) {
            attributes = all_attributes[file_type];
        }
        let present = [];
        const attributesExclude = gtConfig.attributes_to_exclude;
        for (const key of extractedFileValues.keys()) {
            if (attributes.length > 0) {
                if (!attributes.includes(key) && !attributes.some(attr => key.startsWith(attr + '['))) {
                    extractedFileValues.delete(key);
                } else {
                    present.push(key);
                    currentAttributes.current.add(removeIndexFromLabel(key));
                }
            } else {
                if (key.includes('__')) {
                    extractedFileValues.delete(key);
                }
            }
            // handle explicit exclusions
            if (attributesExclude.length > 0 && (attributesExclude.includes(key) || attributesExclude.some(att => key.startsWith(att + '[')))) {
                extractedFileValues.delete(key);
            }
    
            for (let el of attributes) {
                let add = true;
                for (let [key, value] of extractedFileValues.entries()) {
                    const extractedKey = key.split('[')[0]; // Extract key from format "key[value]"
                    if (el === extractedKey) {
                        add = false;
                    }
                }
                const uniqueId = uuidv4();
                const newKey = `${el}[${uniqueId}]`;
                if (add && category === EXTRACTION_CATEGORY) {
                    if (gtConfig.schedules.includes(el)){
                        const newScheduleInitialValue = getScheduleInitialValues(file_type, el);
                        if (newScheduleInitialValue == null){
                            console.log('Schedule Initial value does not exist');
                        } else {
                            extractedFileValues.set(newKey, newScheduleInitialValue);    
                        }
                    } else {
                        extractedFileValues.set(newKey, ["Not Found", "", "", "", "NA", "","", "", "", "", "", ""]);
                    }
                    currentAttributes.current.add(removeIndexFromLabel(key));
                }
            }
        }
        return extractedFileValues;
    }

    const handleClearInput = useCallback(() => {
        selectedTextRef.current = [];
        removeHighlightColorFromSelectingDivs(selectingDivsRefs.current)
        selectingDivsRefs.current = new Set();
    }, []);

    const removeHighlightColorFromSelectingDivs = (divIds) => {
        // Keep the page clean and remove yellow highlights after the values are selected.
        for (let _id of divIds) {
            if (_id !== '') {
                const divElement = document.getElementById(_id);
                /* ADO-67280:
                * after developing code for rendering boxes on-demand
                * divs could already be rmeoved when user scrolling to other pages
                */
                if (divElement){
                    divElement.style.backgroundColor = 'rgba(0, 0, 0, 0)';
                }
            }
        }
    }

    if (!authState) {
        return (
            <div>Loading...</div>
        );
    }

    function showSnackBar(msg, severity){
        setSnackBarMsg(msg);
        setSnackBarSeverity(severity);
        handleOpenSnackBar();
    }

    
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    const handleOpenSnackBar = () => {
        setOpenSnackBar(true);
    };

    const onClickMiddlePanelContentTypeToggle = (contentType) => {
        setMiddlePanelContentType(contentType);
        setTriggerMiddlePanelContentType(!triggerMiddlePanelContentType);
    }

    const onClickOcrFontSizeChange = (fontSize) => {
        setFontValue(fontSize);
        const pageNums = Array.from(textDivRefs.current.keys());
        if (pageNums.length === 0){
            return
        }

        pageNums.forEach((pageNum) => {
            const divIds = textDivRefs.current.get(pageNum);
            if (!divIds){
                return;
            }

            divIds.forEach((id) => {
                const div = document.getElementById(id);
                div.style.fontSize = fontSize + 'px';
            });
        });
    }    

    return (
        <div className="app-container" style={{backgroundColor: theme.palette.background.paper}}>
            <Snackbar
                open={openSnackBar}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Top center position
                autoHideDuration={3000} // Auto hide after 3 seconds
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    message="Snackbar from right div"
                    severity={snackBarSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackBarMsg}
                </Alert>
            </Snackbar>
            {authState.isAuthenticated && userInfo && state.jsonLabels ? (
                <>
                    
                            <div className='header'>
                                <Header 
                                    headerHeight={headerHeight} 
                                    textDivs={textDivs} 
                                    divIdText={divIdText}
                                    searchFeatureChecked={searchFeatureChecked} 
                                    selectedFile={state.selectedFile}
                                    setSearchFeatureChecked={setSearchFeatureChecked} 
                                    confirmedBy={state.confirmedBy} 
                                    confirmedDate={state.confirmedDate}
                                    lastModified={state.lastModified}
                                    isExternalUse={isExternalUse}
                                    savedBy={state.savedBy}
                                    savedDate={state.savedDate}
                                    setValidationIssueModalOpen={setValidationIssueModalOpen}
                                    setLockDocumentModalOpen={setLockDocumentModalOpen}
                                    isPathFinderEnable={isPathFinderEnable}
                                    middlePanelContentType={middlePanelContentType}
                                    onClickMiddlePanelContentTypeToggle={onClickMiddlePanelContentTypeToggle}
                                    onClickOcrFontSizeChange={onClickOcrFontSizeChange}
                                    fontValue={fontValue}
                                />
                            </div>
                            
                        <Stack>
                        <div className='content' style={{backgroundColor: theme.palette.background.paper}}>
                            <Box display="flex" width="100%"> 
                                <Box
                                    sx={{
                                        width: '16%'
                                    }}
                                >
                                    <LeftPanel 
                                        files={state.jsonLabels} 
                                        onFileSelect={handleFileSelect}
                                        totalFieldsConfirmed={state.totalFieldsConfirmed}
                                        isLoading={isLoading}
                                        navOrderBy={navOrderBy}
                                        setNavOrderBy={setNavOrderBy}
                                        isExternalUse={isExternalUse}
                                        externalDetails={externalDetails}
                                        isLockDocumentModalOpen={isLockDocumentModalOpen}
                                        isValidationIssueModalOpen={isValidationIssueModalOpen}
                                        setValidationIssueModalOpen={setValidationIssueModalOpen}
                                        setLockDocumentModalOpen={setLockDocumentModalOpen}
                                    />
                                </Box>
                                
                                <Box 
                                  sx={{
                                    width: '54%'
                                  }}
                                >
                                { state.selectedFile && 
                                    <MiddlePanel selectedFile={state.selectedFile}
                                        jsonExtractionCoords={state.jsonCoords[state.selectedFile]}
                                        jsonTuples={state.jsonCoordsTuples[state.selectedFile]}
                                        selectedKey={state.selectedKey}
                                        extractedValues={state.extractedValues}
                                        onInputChange={handleInputChange}
                                        selectingDivsRefs={selectingDivsRefs}
                                        removeHighlightColorFromSelectingDivs={removeHighlightColorFromSelectingDivs}
                                        handleClearInput={handleClearInput}
                                        pdfDoc={pdfDoc}
                                        isLoading={isLoading}
                                        searchFeatureChecked={searchFeatureChecked}
                                        setTextDivs={setTextDivs}
                                        setDivIdText={setDivIdText}
                                        changedAttributesRef={changedAttributesRef}
                                        fileType={state.file_type}
                                        middlePanelContentType={middlePanelContentType}
                                        setMiddlePanelContentType={setMiddlePanelContentType}
                                        triggerMiddlePanelContentType={triggerMiddlePanelContentType}
                                        canvasMapForOcrRefs={canvasMapForOcrRefs}
                                        selectedTextRef={selectedTextRef}
                                        textDivRefs={textDivRefs}
                                        fontValue={fontValue}
                                    />
                                }
                                </Box>
                                <Box
                                   width="30%"
                                >
                                    <RightPanel
                                        selectedFile={state.selectedFile} 
                                        onKeySelect={handleKeySelection} 
                                        extractedValues={state.extractedValues}
                                        extractedValuesOriginRef={extractedValuesOriginRef}
                                        updateExtractedValues={updateExtractedValues}
                                        handleAddNewAttribute={handleAddNewAttribute} 
                                        handleRestoreAttribute={handleRestoreAttribute}
                                        category={state.category}
                                        file_type={state.file_type}
                                        handleClearInput={handleClearInput}
                                        isLoading={isLoading}
                                        headerHeight={headerHeight}
                                        changedAttributesRef={changedAttributesRef}
                                        handleOpenSnackBar={handleOpenSnackBar}
                                        showSnackBar={showSnackBar}
                                        confirmedBy={state.confirmedBy}
                                        confirmedDate={state.confirmedDate}
                                        savedBy={state.savedBy}
                                        savedDate={state.savedDate}
                                        lastModified={state.lastModified}
                                        isPathFinderEnable={isPathFinderEnable}
                                    />
                                </Box>
                            </Box>
                            
                            <Suspense fallback={<div>Loading...</div>}>
                                {isModalOpen && <AttributesModal
                                    fileType={state.file_type}
                                    isOpen={isModalOpen}
                                    onClose={handleCloseModal}
                                    handleAttributeModalSubmit={handleAttributeModalSubmit}
                                    currentAttributes={currentAttributes.current}
                                    attributeContext={attributeContext.current}
                                />}
                            </Suspense>

                            <Suspense fallback={<div>Loading...</div>}>
                                {isRestoreAttributeModalOpen && <RestoreAttributesModal
                                    extractedValuesOriginRef={extractedValuesOriginRef}
                                    extractedValues={state.extractedValues}
                                    isOpen={isRestoreAttributeModalOpen}
                                    onClose={handleCloseRestoreAttributeModal}
                                    handleRestoreAttributeModalSubmit={handleRestoreAttributeModalSubmit}
                                    
                                />}
                            </Suspense>
                        </div>
                    </Stack>
                
                </>
            ) : (!authState.isAuthenticated && (
                    <Login/>
                )
            )}
        </div>
    );
}

export default Home;
