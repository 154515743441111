import React, { useState } from 'react';
import { ListItemIcon, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import CopyAllIcon from '@mui/icons-material/CopyAll';


const InputBoxMenuContext = ({ inputBoxId, inputBoxKey, inputBoxClassName, inputBoxType, inputBoxValue, inputBoxSize, 
                               inputBoxStyle, mouseDownHandler, changeHandler, onMenuClearClick, cellIndex, rowIndex, cell, attributeKey, type, disabled,
                               onMenuCopyToAllClick}) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  
  // Event handler for right-click
  const handleContextMenu = (event) => {
    event.preventDefault();
    setMenuAnchor(event.currentTarget); // Open Material-UI menu at the click position
  };


  const handleMenuClearClick = (event) => {
    if ( type === 'grid') {
      onMenuClearClick(cellIndex, rowIndex, cell);
    } else {
        onMenuClearClick(attributeKey);
    }
    handleCloseMenu(); // Close the menu
  };

  const handleMenuCopyToAllClick = (event) => {
    if ( type === 'grid') {
      onMenuCopyToAllClick(cellIndex, rowIndex);
    }
    handleCloseMenu();
  }

  const handleCloseMenu = () => {
    setMenuAnchor(null); // Close the menu
  };

  return (
    <div>
    <input className={inputBoxClassName}
            id={inputBoxId}
            type={inputBoxType}
            value={inputBoxValue === null ? '' : inputBoxValue}  // html input does not accept null as value, so we need to convert it to empty string
            size={inputBoxSize}
            style={inputBoxStyle}
            onMouseDown={(e) => mouseDownHandler(e, inputBoxKey)}
            onChange={(e) => changeHandler(e)}
            onContextMenu={handleContextMenu}
            title={inputBoxValue}
            disabled={disabled}
    />

   {!disabled && <Menu
      anchorEl={menuAnchor}
      open={Boolean(menuAnchor)}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={handleMenuClearClick}>
        <ListItemIcon >
              <ClearIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2" fontSize="small">
            Clear
          </Typography>
        </ListItemText>
      </MenuItem>
      { type === 'grid' && <MenuItem onClick={handleMenuCopyToAllClick}>
        <ListItemIcon >
              <CopyAllIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2" fontSize="small">
            Copy to All
          </Typography>
        </ListItemText>
      </MenuItem>
   }
    </Menu>
    }
  </div>
  );
};

export default InputBoxMenuContext;