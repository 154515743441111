import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";


export default function ConfirmAllDialog({acceptConfirmAll, confirmAllDialogOpen, setConfirmAllDialogOpen }){

 
    return (
        <Dialog
            open={confirmAllDialogOpen}
            keepMounted
            onClose={ () => setConfirmAllDialogOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={false}
        >
            <DialogTitle>
                {
                    <span>
                         <Typography variant='h6' color='error' style={{ marginBottom: '10px' }}>Please note that selecting 'Confirm All' will remove the following:</Typography>
                         <Typography variant='body1' color='info' style={{ marginBottom: '5px' }}>- 'Not Found' attributes with an 'empty/null' context</Typography> 
                         <Typography variant='body1' color='info' style={{ marginBottom: '5px' }}>- Empty schedules</Typography>
                         <Typography variant='body1' color='info' style={{ marginBottom: '16px' }}>- Any empty rows within schedules</Typography>
                         <Typography variant='body' color='error'>Are you sure you want to continue?</Typography>
                    </span>
                }

            </DialogTitle>
            <DialogActions>
                <Button sx={{ color: "red" }} onClick={ () => setConfirmAllDialogOpen(false)}>
                    Cancel
                </Button>
                <Button
                    sx={{ color: "green" }}
                    onClick={acceptConfirmAll}
                >
                    Confirm
                </Button>
            </DialogActions>
    </Dialog>
    )
}
