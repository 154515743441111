import check from '../../assets/header/icon-core-check-mark.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LinearProgressWithLabel from '../../share/LinearProgressWithLabel';

function NotificationComponent({notifications}) {
  
    console.log(notifications);
    return (
        <Box>
            <Box className="notif-details">
                <Typography variant='h5' style={{marginLeft:"18px"}}>Notifications</Typography>
                { Array.from(notifications).map( (notification, index) => (
                    notification === 'DOC_RENDER' && 
                        <Box key={index} className="notification notif-success">
                            <img src={check} alt='notification check icon' className="notif-icon-check"></img>
                            <Typography variant="body2" color="text.secondary">Rendering PDF Document: </Typography>
                        </Box> 
                     ))
                }
                {/* <Box className="notification notif-warning">
                    <img src={warn} className="notif-icon-warning"></img>
                    <p  className="menu-info-text">Something bad happened the user needs to know about.</p>
                </Box> */}
            </Box>
            <hr style={{color: "var(--color-gray-2)", borderStyle: "solid"}}></hr>
            <Box className="system-notif-details">
                <p className="menu-title">System Notifications</p>
                <p  className="menu-info-text">No System Notifications at this time.</p>
            </Box>
        </Box>
    );
}

export default NotificationComponent